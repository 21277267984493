import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import firebase from 'firebase';
import "firebase/remote-config";
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
// Material UI
import Button from '@material-ui/core/Button';

// Components
import Layout from './hoc/Layout/Layout';
import withRoot from './withRoot';
import Notifier from './components/UI/Snackbars/Notifier';

// Pages for router
import Start from './pages/Start/Start';
import SignIn from './pages/SignIn/SignIn';
import OverviewMap from './pages/overviewMap';
import OverviewMapNotes from './pages/overviewMapNotes';
import OverviewMapNote from './pages/overviewMapNote';
import OverviewMapNewNote from './pages/overviewMapNewNote';
import OverviewMapPoint from './pages/overviewMapPoint';
import OverviewMapNewPoint from './pages/overviewMapNewPoint';
import OverviewMapInventory from './pages/overviewMapInventory';
import OverviewMapNewInventory from './pages/overviewMapNewInventory';
import OverviewMapNewFeatures from './pages/overviewMapNewFeatures';
import OverviewMapCustomers from './pages/overviewMapCustomers';
import OverviewMapCustomer from './pages/overviewMapCustomer';
import OverviewMapNewCustomer from './pages/overviewMapNewCustomer';
import OverviewMapCommunity from './pages/overviewMapCommunity';
import OverviewMapBiotopePlan from './pages/overviewMapBiotopePlan';
import overviewMapNewBiotopePlan from './pages/overviewMapNewBiotopePlan';
import overviewMapPerson from './pages/overviewMapPerson';
import TextLine from './pages/overviewTextLine';
import Map from './maps/map';

// Actions
import * as actions from './store/actions/index';

// Map specific components
import configFirebase from './backend/configFirebase';
import configGoogleAnalytics from './backend/configGoogleAnalytics';
import configFacebook from './backend/configFacebook';
import { Typography } from '@material-ui/core';

// configuration functionality
import { setAppUserConfiguration } from './config/setConfiguration';
const configHDBasis = require('./config/configHDBasis.json');
const configHDUdvidet = require('./config/configHDUdvidet.json');
const configSDBasis = require('./config/configSDBasis.json');
const configSDUdvidet = require('./config/configSDUdvidet.json');
const configSDInternal = require('./config/configSDInternal.json');
const configSLSBasis = require('./config/configSLSBasis.json');
const configSLSUdvidet = require('./config/configSLSUdvidet.json');
const configSLSForvalter = require('./config/configSLSForvalter.json');
const configEjerBasis = require('./config/configEjerBasis.json');
const configEjerUdvidet = require('./config/configEjerUdvidet.json');
const configForvalterUdvidet = require('./config/configForvalterUdvidet.json');
const configHunting = require('./config/configHunting.json');
const configHuntingPlus = require('./config/configHuntingPlus.json');

// Setup firebase app and remote config
firebase.initializeApp(configFirebase);

// Parameters
// const minimumWindowWidth = 1000;
// const minimumWindowHeight = 700;
// const allowedURLParams = ['', 'opret-konto', 'login', 'glemt-adgangskode', 'hededanmark', 'sls', 'skovdyrkerne'];
const allowedURLParams = ['', 'login', 'glemt-adgangskode', 'hededanmark', 'dalgas'];
const allowPersistenceInnerWidth = 1100;
const TIME_OUT = 15000;//15000;

class App extends Component {
    state = {
        dataLoaded: false,
        dataLoadedStart: false,
        orgDataLoadedStart: false,
        layoutFeaturesSet: false,

        tempOrg: null,
        browserTypeOk: true,
        windowSizeOk: true,
        routerReset: false,
        urlParam: '',
        urlParamSolution: '',
        urlParamSolutionVersion: '',
        urlParamEmail: '',
        affiliation: '',
        firstTimeUpdate: true,
        pageNotFound: true,
        tempHDAffilitaion: null,

        tempNatureActive: false,
        tempOperationActive: false,
        tempHuntingActive: false,
        tempHuntingPlusActive: false,
        tempOperationPlusActive: false,
        tempCheckSelectedChange: true,
        tempNumberOfForests: null,

        // Test parameters
        appLoadStartTime: null,
        firestoreDataUserLoadStartTime: null,
        firestoreDataUserLoadStartTimeDone: false,
        firestoreDataOrgLoadStartTime: null,
        firestoreDataOrgLoadStartTimeDone: false,
        reepayDataLoadStartTime: null,
        reepayDataLoadStartTimeDone: false,
    }

    testEndTime = (startTime, type) => {
        const endTime = new Date();
        let timeDiff = endTime - startTime;
        timeDiff = timeDiff / 1000; // ms -> s
        let conType = 'NA';
        let conSpeed = 'NA';
        if (navigator && navigator.connection) {
            if (navigator.connection.effectiveType) conType = navigator.connection.effectiveType;
            if (navigator.connection.downlink) conSpeed = navigator.connection.downlink;
        }
        // console.log(`Timer: ${type} [s]: ${timeDiff}`)
        // console.log(`Connection type: ${conType}`);
        // console.log(`Connection speed [Mbps]: ${conSpeed}`)
        ReactGA.event({
            category: 'Performance',
            action: `T[s]: ${timeDiff}. #f: ${this.props.currentAuthUser.forests.length}. C-type: ${conType}. C-speed[Mbps]: ${conSpeed}`,
            label: `User: ${this.props.currentAuthUser.name}`
        });
    }

    componentWillMount() {
        // Redirect to dalgas on hededanmark
        if (window.location.pathname.includes('hededanmark')) {
            window.location.replace('/dalgas');
        }
        // Firebase
        // Add offline persistence to firestore
        // Only add persistence for for mobile devices
        // TODO : Implement error handling on persistant
        if (window.innerWidth <= allowPersistenceInnerWidth) {
            firebase.firestore().enablePersistence()
                .catch((err) => {
                    if (err.code === 'failed-precondition') {
                        // Multiple tabs open, persistence can only be enabled
                        // in one tab at a a time.
                        // ...
                        this.props.onEnqueueSnackbar({
                            message: 'Offline funktionalitet er kun tilgængelig med én fane. Luk alle andre faner med Skovkortet.dk!',
                            options: {
                                key: new Date().getTime() + Math.random(),
                                variant: 'info',
                            },
                        });

                    } else if (err.code === 'unimplemented') {
                        // The current browser does not support all of the
                        // features required to enable persistence
                        // ...
                        this.props.onEnqueueSnackbar({
                            message: 'Din browser understøtter ikke offline funktionalitet. Vi anbefaler du anvender Google Chrome.',
                            options: {
                                key: new Date().getTime() + Math.random(),
                                variant: 'info',
                            },
                        });
                    }
                });
        }

        // Google Analytics
        ReactGA.initialize(configGoogleAnalytics.id);
        ReactGA.pageview('/application/');

        // Facebook Pixels
        // Setup Facebook pixels
        const options = {
            autoConfig: true, // set pixel's autoConfig
            debug: false, // enable logs
        };
        ReactPixel.init(configFacebook.pixelID, options);

        // Check auth
        this.props.onCheckOfAuth();

        // Check browser networkstatus
        if (window.navigator.onLine) {
            this.props.onSetNetworkStatus("online");
        } else {
            this.props.onSetNetworkStatus("offline");
        }
        // Set event listners for detecting online/offline 
        window.addEventListener('online', () => {
            this.props.onSetNetworkStatus("online");
            this.props.onEnqueueSnackbar({
                message: 'Forbindelse til internet genetableret!',
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: 'info',
                },
            });
        });
        window.addEventListener('offline', () => {
            this.props.onSetNetworkStatus("offline");
            this.props.onEnqueueSnackbar({
                message: 'Ingen forbindelse til internet!',
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: 'warning',
                },
            });
        });

        // --- Set url parameters --- //
        // Check for url parameter validity
        let urlParam = window.location.pathname.split('/')[1].split(':')[0];
        // Set affiliation based on url param
        if (urlParam === "dalgas") {
            urlParam = "hededanmark";
        }
        let affiliation = urlParam;
        let urlParamSolution = '';
        let urlParamSolutionVersion = '';
        let urlParamEmail = '';
        if (affiliation === '' || affiliation === 'opret-konto') {
            affiliation = window.location.pathname.split(':')[2] ? window.location.pathname.split(':')[2] : '';
            urlParamSolution = window.location.pathname.split(':')[1] ? window.location.pathname.split(':')[1].split('-')[0] : '';
            urlParamSolutionVersion = window.location.pathname.split(':')[1] ? window.location.pathname.split(':')[1] : '';
        } else if (affiliation === 'login') {
            urlParamEmail = window.location.pathname.split(':')[1] ? window.location.pathname.split(':')[1] : '';
        } else if (affiliation === 'skovdyrkerne' || affiliation === 'hededanmark') {
            urlParamEmail = window.location.pathname.split(':')[1] ? window.location.pathname.split(':')[1] : '';
            urlParamSolution = 'skovejeren';
            urlParamSolutionVersion = 'skovejeren';
        } else {
            urlParamSolution = window.location.pathname.split(':')[1] ? window.location.pathname.split(':')[1].split('-')[0] : '';
            urlParamSolutionVersion = window.location.pathname.split(':')[1] ? window.location.pathname.split(':')[1] : '';
        }

        // Check for invalid url param and default to index /
        if (!allowedURLParams.includes(urlParam)) {
            urlParam = '';
            window.location.replace('/');
        }

        this.setState({
            urlParam: urlParam,
            urlParamSolution: urlParamSolution,
            urlParamSolutionVersion: urlParamSolutionVersion,
            urlParamEmail: urlParamEmail,
            affiliation: affiliation
        })
    }

    ingonreWarningClickHandler = () => {
        this.setState({ browserTypeOk: true, windowSizeOk: true });
    }

    componentDidMount() {
        // Set reload on time out (Be aware that this might end in an infinite loop)
        // setTimeout(() => {
        //     if (!this.state.dataLoaded) {
        //         firebase.firestore().terminate().catch(error => console.error(error))
        //         firebase.firestore().clearPersistence().catch(error => {
        //             console.error('Could not enable persistence:', error.code);
        //         })
        //         window.location.reload()
        //     };
        // }, TIME_OUT);
        // this.props.onFetchAppConfig('hd_not_payed_user');

        // Fetch terms
        this.props.onFetchTerms();

    }

    componentDidUpdate() {
        // --- Check if user affilitation data chenges ---//
        if (this.props.currentAuthUser && this.props.currentAuthUser.affiliation && this.state.tempHDAffilitaion !== this.props.currentAuthUser.affiliation.HD) {
            this.props.onSetAppConfigState("setAppConfig", true)
            this.setState({ tempHDAffilitaion: this.props.currentAuthUser.affiliation.HD, dataLoadedStart: false })
        }
        // --- Set App Layout features --- //
        if (!this.state.layoutFeaturesSet && this.state.dataLoaded) {
            setTimeout(() => { this.props.onSetDisplayUpgradeBox(true); }, 2000);
            this.setState({ layoutFeaturesSet: true });
        }
        // --- Check for HD selected forest change from cache --- //
        // if (this.state.tempCheckSelectedChange &&
        //     this.props.currentAuthUser &&
        //     this.props.currentAuthUser.selectedForests &&
        //     this.props.currentAuthUser.selectedForests.length > 0 &&
        //     this.props.selectedForest && this.state.urlParam === 'hededanmark' &&
        //     this.props.selectedForest !== this.props.currentAuthUser.selectedForests[0]) {
        //     // TODO : Check if this is the best solution
        //     this.props.onSetCurrentlySelectedForestId(this.props.currentAuthUser.selectedForests[0]);
        //     this.setState({ tempCheckSelectedChange: false })
        // }
        // --- Init app config based on user information --- //
        if (this.props.config.setAppConfig && this.props.currentAuthUser) {
            try {
                // Get configuration based on user information
                const configObj = setAppUserConfiguration(this.props.currentAuthUser);
                // Set configurations
                if (configObj.configs && configObj.configs.length > 0) {
                    configObj.configs.forEach(conf => {
                        this.props.onDeepMergeAppConfigState(conf);
                    })
                }
                // Set subscription state based on
                if (configObj.subs && configObj.subs.length > 0) {
                    configObj.subs.forEach(sub => {
                        this.props.onSetSubscriptionState(sub, true);
                    })
                }
                // Set species scheme
                if (configObj.specieScheme) this.props.onSetSpeciesScheme(configObj.specieScheme);
            } catch (error) {
                console.error(error)
            }

            this.props.onSetAppConfigState("setAppConfig", false)
        }

        // Forest owners
        if (this.state.tempNatureActive !== this.props.natureActive) {
            if (this.props.natureActive) {
                this.props.onDeepMergeAppConfigState(configHunting);
                if (this.props.currentAuthUser.affiliation && this.props.currentAuthUser.affiliation.HD && this.props.currentAuthUser.affiliation.HD.status === 'active') {
                    // HedeDanmark forest owner basis
                    this.props.onDeepMergeAppConfigState(configHDBasis);
                } else if (this.props.currentAuthUser.affiliation && this.props.currentAuthUser.affiliation.sls && this.props.currentAuthUser.affiliation.sls.status === 'active') {
                    // SLS forest owner basis
                    this.props.onDeepMergeAppConfigState(configSLSBasis);
                } else if (this.props.currentAuthUser.affiliation && this.props.currentAuthUser.affiliation.skovdyrkerne && this.props.currentAuthUser.affiliation.skovdyrkerne.status === 'active') {
                    // Skovdyrkerne forest owner basic
                    this.props.onDeepMergeAppConfigState(configSDBasis);
                } else {
                    // Normal forest owner basis
                    this.props.onDeepMergeAppConfigState(configEjerBasis);
                }
            }
            this.setState({ tempNatureActive: this.props.natureActive });
        }
        if (this.state.tempOperationActive !== this.props.operationActive) {
            if (this.props.operationActive) {
                this.props.onDeepMergeAppConfigState(configHunting);
                if (this.props.currentAuthUser.affiliation && this.props.currentAuthUser.affiliation.HD && this.props.currentAuthUser.affiliation.HD.status === 'active') {
                    // HedeDanmark forest owner udvidet
                    this.props.onDeepMergeAppConfigState(configHDUdvidet);
                } else if (this.props.currentAuthUser.affiliation && this.props.currentAuthUser.affiliation.sls && this.props.currentAuthUser.affiliation.sls.status === 'active') {
                    // SLS forest owner udvidet
                    this.props.onDeepMergeAppConfigState(configSLSUdvidet);
                } else if (this.props.currentAuthUser.affiliation && this.props.currentAuthUser.affiliation.skovdyrkerne && this.props.currentAuthUser.affiliation.skovdyrkerne.status === 'active') {
                    // Skovdyrkerne owner udvidet
                    this.props.onDeepMergeAppConfigState(configSDUdvidet);
                } else {
                    // Normal forest owner udvidet
                    this.props.onDeepMergeAppConfigState(configEjerUdvidet);
                }
            }
            this.setState({ tempOperationActive: this.props.operationActive });
        }
        // Foresters
        if (this.state.tempOperationPlusActive !== this.props.operationPlusActive) {
            if (this.props.operationPlusActive) {
                if (this.props.currentAuthUser.affiliation && this.props.currentAuthUser.affiliation.sls && this.props.currentAuthUser.affiliation.sls.status === 'active'
                    && this.props.currentAuthUser.userTags && this.props.currentAuthUser.userTags.some(tag => ["Admin", "Skovfoged", "Skovejer"].includes(tag))) {
                    // SLS forester udvidet
                    this.props.onDeepMergeAppConfigState(configSLSForvalter);
                } else if (this.props.currentAuthUser.affiliation && this.props.currentAuthUser.affiliation.skovdyrkerne 
                    && this.props.currentAuthUser.affiliation.skovdyrkerne.status === 'active'
                    && this.props.currentAuthUser.affiliation.skovdyrkerne.isForester) {
                    // Skovdyrkerne internal
                    this.props.onDeepMergeAppConfigState(configSDInternal);
                } else {
                    // Normal forester udvidet
                    this.props.onDeepMergeAppConfigState(configForvalterUdvidet);
                    this.props.onDeepMergeAppConfigState(configHunting);
                }
            }
            this.setState({ tempOperationPlusActive: this.props.operationPlusActive })
        }
        // Hunting
        if (this.state.tempHuntingActive !== this.props.huntingActive) {
            if (this.props.huntingActive) {
                this.props.onDeepMergeAppConfigState(configHunting);
            }
            this.setState({ tempHuntingActive: this.props.huntingActive })
        }
        if (this.state.tempHuntingPlusActive !== this.props.huntingPlusActive) {
            if (this.props.huntingPlusActive) {
                this.props.onDeepMergeAppConfigState(configHuntingPlus);
            }
            this.setState({ tempHuntingPlusActive: this.props.huntingPlusActive })
        }

        // --- Handle first time a forest owner creates a forest --- //
        // if (this.state.firstTimeUpdate && this.props.currentAuthUser && this.props.currentAuthUser.userRole === 'forestOwner' && this.props.currentAuthUser.forests.length === 1 && !this.props.selectedForest) {
        //     this.props.onSetCurrentlySelectedForestId(this.props.currentAuthUser.forests[0]);
        //     this.setState({ firstTimeUpdate: false })
        // }
        // Handle user assigned to org
        if (this.props.currentAuthUser && this.props.currentAuthUser.org && this.props.currentAuthUser.org !== '' && this.state.tempOrg !== this.props.currentAuthUser.org) {
            this.fetchOrgData(this.props.currentAuthUser.org);
            this.setState({ tempOrg: this.props.currentAuthUser.org });
        }
        // Handle new forest added/removed to user externally
        // if (this.props.updateCustomerIdListFlag) {
        if (this.props.updateCustomerIdListFlag || (this.state.dataLoaded && this.props.customerIdList && this.state.tempNumberOfForests !== this.props.currentAuthUser.forests.length)) {
            // Check if new forest is added or removed
            if (this.props.currentAuthUser.forests.length > this.props.customerIdList.length) {
                // New forest added
                // Get new forest id
                const newForestId = this.props.currentAuthUser.forests.filter(x => !this.props.customerIdList.includes(x));
                newForestId.forEach(id => {
                    // Add cloud event listner on notes customers and cells
                    this.props.onFetchNotes(id);
                    this.props.onFetchCustomers(id, false);
                    this.props.onFetchCells(id, 0);
                    this.props.onFetchPoints(id, 0);
                    this.props.onFetchForestBbox(id, 0);
                    this.props.onFetchDocumentList(id);
                    // Add event listners on hunting features
                    this.props.onFetchHuntingFeatures(id, 0)
                    this.props.onFetchHuntingPoints(id, 0)
                })

                // Update customer id list
                this.props.onSetCustomerIdList(this.props.currentAuthUser.forests);

            } else if (this.props.currentAuthUser.forests.length < this.props.customerIdList.length) {
                const deletedForestId = this.props.customerIdList.filter(x => !this.props.currentAuthUser.forests.includes(x));
                // Remove deleted forest from notes and cells
                this.props.onDeleteNotesForest(deletedForestId);
                this.props.onDeleteCellsForest(deletedForestId);
                this.props.onDeleteBboxForest(deletedForestId);
                this.props.onRemoveDocumentList(deletedForestId);
                // Remove all hunting features and points
                this.props.onDeleteAllHuntingFeatures(deletedForestId);
                this.props.onDeleteAllHuntingPoints(deletedForestId);

                // Update customer id list
                this.props.onSetCustomerIdList(this.props.currentAuthUser.forests);
            }

            // Update org list
            if (this.props.org && this.props.customerListOrg && this.props.org.forests.length > Object.keys(this.props.customerListOrg).length) {
                const newForestIdOrg = this.props.org.forests.filter(x => !Object.keys(this.props.customerListOrg).includes(x));
                this.props.onFetchCustomers(newForestIdOrg[0], true);
            }
            this.setState({ tempNumberOfForests: this.props.currentAuthUser.forests.length })
            this.props.onSetUpdateCustomerIdListFlag(false);
        }
        // Handle new biotope plan added/removed to user
        if (this.props.updateBiotopePlanFlag) {
            // Check if new biotope plan has been added or removed
            if (this.props.currentAuthUser.biotopePlans && this.props.currentAuthUser.biotopePlans.length > Object.keys(this.props.biotopePlansUser).length) {
                // New biotope plan added
                const newBPid = this.props.currentAuthUser.biotopePlans.filter(x => !Object.keys(this.props.biotopePlansUser).includes(x));
                this.props.onFetchBiotopePlan(newBPid[0], false);
                this.props.onFetchBiotopPlanFeatures(newBPid[0], 0);
                this.props.onFetchBiotopePlanPoints(newBPid[0], 0);

            } else if (this.props.currentAuthUser.biotopePlans && this.props.currentAuthUser.biotopePlans.length < Object.keys(this.props.biotopePlanFeatures).length) {
                // Remove biotope plan features from list
                const deletedBPid = Object.keys(this.props.biotopePlanFeatures).filter(x => !this.props.currentAuthUser.biotopePlans.includes(x));
                this.props.onDeleteBiotopePlanFeatures(deletedBPid[0])
            }
            // Update org list
            if (this.props.org && this.props.org.biotopePlans && this.props.org.biotopePlans.length > Object.keys(this.props.biotopePlansOrg).length) {
                const newBPid = this.props.org.biotopePlans.filter(x => !Object.keys(this.props.biotopePlansOrg).includes(x));
                this.props.onFetchBiotopePlan(newBPid[0], true);
            }
            this.props.onSetUpdateBiotopePlanFlag(false);
        }
        // Send page view to analytics
        ReactGA.pageview(`/application${this.props.history.location.pathname}`);
    }

    componentWillUpdate(nextProps) {
        // TODO
        // Hack version to update UserID in ReactGA
        // Reinitialize with user - only happens during auth when user is not authenticated yet
        // nextProps used to control when reinit takes places
        if (nextProps !== null && this.props.user === null) {
            ReactGA.initialize(configGoogleAnalytics.id, {
                gaOptions: {
                    userId: nextProps.user.uid
                }
            });
        }
    }

    browserCheck = () => {
        // Return content if Internet Explorer
        return (
            <div
                style={{
                    position: 'absolute', left: '50%', top: '50%',
                    transform: 'translate(-50%, -50%)',
                    whiteSpace: 'pre-wrap',
                }}
            >
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <div>
                        <div>
                            <div style={{ fontWeight: 600 }}>BEMÆRK!</div>
                            <div style={{ color: '#333333' }}>Du er ved at åbne programmet med browseren Microsoft Internet Explorer.<br />
                                Denne browser supporteres ikke længere af Microsoft og vil derfor ikke fungere<br />med vores applikation.<br />
                                Vi anbefaler Googles browser Chrome, da det er én af de førende inden for<br />
                                browsere og vores program er optimeret til at køre i Chrome.</div>
                            <a href="https://www.google.com/chrome/">Klik her for at hente Chrome</a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    fetchAccountData = () => {
        this.props.onFetchReepayState();
        this.props.onFetchPaymentDetails();
        this.props.onFetchReepayPlanList();
    }

    fetchForestData = (forestId) => {
        // Forest features
        this.props.onFetchNotes(forestId);
        this.props.onFetchCustomers(forestId);
        this.props.onFetchCells(forestId, 0);
        this.props.onFetchPoints(forestId, 0);
        this.props.onFetchForestBbox(forestId, 0);
        this.props.onFetchDocumentList(forestId);
        // Hunting features
        this.props.onFetchHuntingFeatures(forestId, 0)
        this.props.onFetchHuntingPoints(forestId, 0)
    }

    fetchBiotopePlanData = (planId, isOrg) => {
        this.props.onFetchBiotopePlan(planId, isOrg);
        // Biotope plan features
        this.props.onFetchBiotopPlanFeatures(planId, 0)
        this.props.onFetchBiotopePlanPoints(planId, 0);
    }

    fetchOrgData = (orgId) => {
        // Org data
        this.props.onFetchOrg(orgId);
        // Org teams
        this.props.onFetchOrgTeams(orgId);
    }

    authenticated() {
        const { user } = this.props;
        if (user !== null && user !== false) {
            // TEST : timer testing
            if (!this.state.appLoadStartTime) {
                this.setState({appLoadStartTime: new Date()});
            }
            // if (!this.state.firestoreDataUserLoadStartTime && !this.state.firestoreDataUserLoadStartTimeDone) {
            //     this.setState({firestoreDataUserLoadStartTime: new Date()});
            // }
            // if (!this.state.firestoreDataOrgLoadStartTime && !this.state.firestoreDataOrgLoadStartTimeDone) {
            //     this.setState({firestoreDataOrgLoadStartTime: new Date()});
            // }
            // if (!this.state.reepayDataLoadStartTime && !this.state.reepayDataLoadStartTimeDone) {
            //     this.setState({reepayDataLoadStartTime: new Date()});
            // }
            // Check browser location
            if (!this.state.routerReset) {
                // Check router and reset to /
                if (this.props.location !== '/') {
                    this.props.history.replace({
                        pathname: '/',
                    });
                }
                this.setState({ routerReset: true })
            }
            // Get all data on the cloud
            if (!this.state.dataLoadedStart && this.props.currentAuthUser && !this.props.signupInProgress) {
                // --- Fetch information from user data --- //
                // Fetch reepay subscription information
                this.fetchAccountData();
                // Fetch forests and data associated to these
                const forestsUser = this.props.currentAuthUser.forests ? this.props.currentAuthUser.forests : [];
                if (forestsUser) {
                    if (forestsUser.length > 0) {
                        this.props.currentAuthUser.forests.forEach(fId => {
                            this.fetchForestData(fId);
                        });
                        // If the user only has one forest in the list default to that
                        let initForest = null;
                        if (this.state.urlParam === 'hededanmark' && this.props.currentAuthUser.selectedForests && this.props.currentAuthUser.selectedForests.length > 0) {
                            initForest = this.props.currentAuthUser.selectedForests[0];
                        } else if (this.state.urlParam === 'skovdyrkerne' && this.props.currentAuthUser.selectedForests && this.props.currentAuthUser.selectedForests.length > 0) {
                            initForest = this.props.currentAuthUser.selectedForests[0];
                        } else if (this.props.currentAuthUser.forests.length === 1) {
                            initForest = this.props.currentAuthUser.forests[0];
                        }
                        this.props.onSetCurrentlySelectedForestId(initForest);
                        this.props.onSetCustomerIdList(forestsUser);
                    } else {
                        this.props.onSetCustomerIdList([]);
                        this.setState({ dataLoaded: true })
                    }
                }
                // Fetch biotope plans
                const biotopePlansUser = this.props.currentAuthUser.biotopePlans ? this.props.currentAuthUser.biotopePlans : [];
                if (biotopePlansUser && biotopePlansUser.length > 0) {
                    biotopePlansUser.forEach(id => {
                        this.fetchBiotopePlanData(id, false);
                    })
                }
                // If user is part of an organisation fetch data
                if (this.props.currentAuthUser.org && this.props.currentAuthUser.org !== '') {
                    // Fetch org data
                    this.fetchOrgData(this.props.currentAuthUser.org);
                }
                // Set state indicating that data fetching has startet
                this.setState({ dataLoadedStart: true })
            }
            // If user is part of org. Fetch org data
            if (!this.state.orgDataLoadedStart && this.props.org) {
                // Fetch org users
                this.props.onFetchOrgUsers(this.props.org.users);
                // Fetch forest data
                let forestsOrg = this.props.org.forests ? this.props.org.forests : [];
                if (forestsOrg.length > 0) {
                    forestsOrg.forEach(fId => {
                        this.props.onFetchCustomers(fId, true);
                    })
                } else {
                    this.props.onFetchCustomers('', true);
                }
                // Fetch biotope plans
                let biotopePlansOrg = this.props.org.biotopePlans ? this.props.org.biotopePlans : [];
                if (biotopePlansOrg.length > 0) {
                    biotopePlansOrg.forEach(id => {
                        this.props.onFetchBiotopePlan(id, true);
                    })
                }
                // else {
                //     this.props.onFetchBiotopePlan('', true);
                // }
                // Set state indicating that data fetching has startet
                this.setState({ orgDataLoadedStart: true })
            }
            // Indicate that all data has been loaded

            if (this.props.currentAuthUser !== null && this.props.forestBbox !== null && this.props.notes !== null && this.state.browserTypeOk && this.state.windowSizeOk && !this.state.dataLoaded) {
                // Handle timeout issues
                // setTimeout(() => { this.setState({ dataLoaded: true }) }, 200);
                // To check if the same forest is present 2 times in the data
                // const lookup = this.props.org.forests.reduce((a, e) => {
                //   a[e] = ++a[e] || 0;
                //   return a;
                // }, {});
                if (this.props.currentAuthUser.forests.length === Object.keys(this.props.forestBbox).length && Object.keys(this.props.notes).length === Object.keys(this.props.forestBbox).length) {
                    // if (!this.state.firestoreDataUserLoadStartTimeDone) {
                    //     this.testEndTime(this.state.firestoreDataUserLoadStartTime, 'Firestore user data load time');
                    //     this.setState({firestoreDataUserLoadStartTimeDone: true})
                    // }
                    
                    if (this.props.currentAuthUser.org) {
                        if (this.props.org && this.props.customerListOrg) {
                            if (Math.abs(this.props.org.forests.length - Object.keys(this.props.customerListOrg).length) < 2) {
                                // if (!this.state.firestoreDataOrgLoadStartTimeDone) {
                                //     this.testEndTime(this.state.firestoreDataOrgLoadStartTime, 'Firestore org data load time');
                                //     this.setState({firestoreDataOrgLoadStartTimeDone: true})
                                // }
                                this.testEndTime(this.state.appLoadStartTime, 'App load time');
                                this.setState({ dataLoaded: true })
                            }
                        }
                    } else {
                        this.testEndTime(this.state.appLoadStartTime, 'App load time');
                        this.setState({ dataLoaded: true })
                    }
                }
            }
        }
        // Make checks before rendering
        if (user === false) {
            return (
                <SignIn
                    urlParam={this.state.urlParam}
                    urlParamSolution={this.state.urlParamSolution}
                    urlParamSolutionVersion={this.state.urlParamSolutionVersion}
                    urlParamEmail={this.state.urlParamEmail}
                    affiliation={this.state.affiliation}
                />
            )
        } else if ((!this.state.browserTypeOk || !this.state.windowSizeOk) && user !== false && user !== null) {
            return (
                <div
                    style={{
                        position: 'absolute', left: '50%', top: '50%',
                        transform: 'translate(-50%, -50%)'
                    }}
                >
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <div>
                            <Typography
                                variant='h6'
                                gutterBottom
                            >
                                Bemærk
                            </Typography>

                            {!this.state.browserTypeOk && <Typography
                                variant='body1'
                                style={{ marginBottom: 20 }}
                            >
                                Du er ved at åbne programmet med en anden browser end Chrome. Googles browser Chrome er én af de førende inden for browsere og vores program er optimeret til at køre i Chrome. Du kan muligvis stadig benytte denne browser, men der kan være funktioner, som ikke kører optimalt.
                            </Typography>}

                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={this.ingonreWarningClickHandler}
                            >
                                Ignorere
                            </Button>
                        </div>
                    </div>
                </div>
            )
        } else if (this.state.dataLoaded) {
            return (
                <div>
                    <Map />

                    <Notifier />

                    <Switch>
                        <Route path="/" exact component={OverviewMap} />
                        <Route path="/Notes" exact component={OverviewMapNotes} />
                        <Route path="/Notes/NewNote" exact component={OverviewMapNewNote} />
                        <Route path="/Notes/:id" component={withRouter(OverviewMapNote)} />
                        <Route path="/Points/NewPoint" exact component={OverviewMapNewPoint} />
                        <Route path="/Points/:id" exact component={withRouter(OverviewMapPoint)} />
                        <Route path="/Cells/NewCell" exact component={OverviewMapNewInventory} />
                        <Route path="/Cells/:id" component={withRouter(OverviewMapInventory)} />
                        <Route path="/overviewMapNewFeatures" exact component={OverviewMapNewFeatures} />
                        <Route path="/Customers" exact component={OverviewMapCustomers} />
                        <Route path="/Customers/NewCustomer" exact component={OverviewMapNewCustomer} />
                        <Route path="/Customers/:id" component={OverviewMapCustomer} />
                        <Route path="/BiotopePlans/NewBiotopePlan" exact component={overviewMapNewBiotopePlan} />
                        <Route path="/BiotopePlans/:id" component={OverviewMapBiotopePlan} />
                        <Route path="/People/:id" component={overviewMapPerson} />
                        <Route path="/Community" component={OverviewMapCommunity} />
                        <Route path="/TextLine" exact component={TextLine} />
                    </Switch>
                </div>
            )
        } else {
            return (
                <Start />
            )
        }
    }

    render() {
        console.log("State: ", this.state)
        //Trident
        const content = window.navigator.userAgent.indexOf('Trident') !== -1 ? this.browserCheck() : this.authenticated();
        return (
            <div>
                <Layout>
                    {content}
                </Layout>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        config: state.appConfig,
        networkStatus: state.layout.networkStatus,
        notes: state.notes.Notes,
        forestBbox: state.maps.forestBbox,
        user: state.user.User,
        currentAuthUser: state.user.currentAuthUser,
        reepayState: state.account.reepayState,
        org: state.org.org,
        customerList: state.customer.customerList,
        customerListOrg: state.customer.customerListOrg,
        customerIdList: state.customer.customerIdList,
        updateCustomerIdListFlag: state.customer.updateCustomerIdListFlag,
        signupInProgress: state.user.signupInProgress,
        biotopePlansUser: state.biotopePlans.biotopePlansUser,
        biotopePlansOrg: state.biotopePlans.biotopePlansOrg,
        updateBiotopePlanFlag: state.biotopePlans.updateBiotopePlanFlag,
        biotopePlanFeatures: state.biotopePlans.features,
        selectedForest: state.customer.currentlySelectedForestId,
        natureActive: state.account.natureActive,
        operationActive: state.account.operationActive,
        operationPlusActive: state.account.operationPlusActive,
        huntingActive: state.account.huntingActive,
        huntingPlusActive: state.account.huntingPlusActive,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onCheckOfAuth: () => dispatch(actions.checkOfAuth()),
        onFetchTerms: () => dispatch(actions.fetchTerms()),
        onDeepMergeAppConfigState: (state) => dispatch(actions.deepMergeAppConfigState(state)),
        onSetAppConfigState: (key, val) => dispatch(actions.setAppConfigState(key, val)),
        onSetSpeciesScheme: (s) => dispatch(actions.setSpeciesScheme(s)),
        onSetDisplayUpgradeBox: (s) => dispatch(actions.setDisplayUpgradeBox(s)),
        onFetchAppConfig: (file) => dispatch(actions.fetchAppConfig(file)),
        onSetNetworkStatus: (status) => dispatch(actions.setNetworkStatus(status)),
        onFetchReepayState: () => dispatch(actions.fetchReepayState()),
        onFetchPaymentDetails: () => dispatch(actions.fetchPaymentDetails()),
        onFetchReepayPlanList: () => dispatch(actions.fetchReepayPlanList()),
        onFetchOrg: (orgId) => dispatch(actions.fetchOrg(orgId)),
        onFetchOrgTeams: (orgId) => dispatch(actions.fetchOrgTeams(orgId)),
        onFetchOrgUsers: (userIds) => dispatch(actions.fetchOrgUsers(userIds)),
        onFetchNotes: (forestId) => dispatch(actions.fetchNotes(forestId)),
        onFetchDocumentList: (forestId) => dispatch(actions.fetchDocumentList(forestId)),
        onRemoveDocumentList: (forestId) => dispatch(actions.removeDocumentList(forestId)),
        onFetchCells: (forestId, revId) => dispatch(actions.fetchCells(forestId, revId)),
        onFetchForestBbox: (forestId, revId) => dispatch(actions.getForestBbox(forestId, revId)),
        onSetCurrentlySelectedForestId: (forestId) => dispatch(actions.setCurrentlySelectedForestId(forestId)),
        onFetchCustomers: (forestId, org) => dispatch(actions.fetchCustomers(forestId, org)),
        onFetchBiotopePlan: (id, org) => dispatch(actions.fetchBiotopePlan(id, org)),
        onSetCustomerIdList: (idList) => dispatch(actions.setCustomerIdList(idList)),
        onSetUpdateCustomerIdListFlag: (s) => dispatch(actions.setUpdateCustomerIdListFlag(s)),
        onDeleteNotesForest: (id) => dispatch(actions.deleteNotesForest(id)),
        onDeleteCellsForest: (id) => dispatch(actions.deleteCellsForest(id)),
        onDeleteBboxForest: (id) => dispatch(actions.deleteBboxForest(id)),
        onCellsRedraw: () => dispatch(actions.cellsRedrawn()),
        onEnqueueSnackbar: (message, options) => dispatch(actions.enqueueSnackbar(message, options)),
        onFetchHuntingFeatures: (forestId, revId) => dispatch(actions.fetchHuntingFeatures(forestId, revId)),
        onFetchHuntingPoints: (forestId, revId) => dispatch(actions.fetchHuntingPoints(forestId, revId)),
        onDeleteAllHuntingFeatures: (forestId) => dispatch(actions.deleteAllHuntingFeatures(forestId)),
        onDeleteAllHuntingPoints: (forestId) => dispatch(actions.deleteAllHuntingPoints(forestId)),
        onFetchPoints: (forestId, revId) => dispatch(actions.fetchPoints(forestId, revId)),
        onFetchBiotopPlanFeatures: (id, revId) => dispatch(actions.fetchBiotopePlanFeatures(id, revId)),
        onFetchBiotopePlanPoints: (id, revId) => dispatch(actions.fetchBiotopePlanPoints(id, revId)),
        onSetUpdateBiotopePlanFlag: (s) => dispatch(actions.setUpdateBiotopePlanFlag(s)),
        onDeleteBiotopePlanFeatures: (id) => dispatch(actions.deleteBiotopePlanFeatures(id)),
        onSetCurrentlySelectedBiotopePlanId: (id) => dispatch(actions.setCurrentlySelectedBiotopePlanId(id)),
        onSetSubscriptionState: (sub, state) => dispatch(actions.setSubscriptionState(sub, state)),
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withRoot(App)));