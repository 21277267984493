import React from "react";
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import ReactGA from 'react-ga';

import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import daLocale from "date-fns/locale/da";
import moment from 'moment';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Input from '@material-ui/core/Input';

// Material UI Icons
import VisibilityIcon from '@material-ui/icons/VisibilityRounded';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOffRounded';
import GoBackIcon from '@material-ui/icons/KeyboardArrowLeftRounded';
import IconChevronRight from '@material-ui/icons/ChevronRightRounded';
import SendIcon from '@material-ui/icons/SendRounded';
import DateRangeIcon from '@material-ui/icons/DateRangeRounded';

// Components
import ImageGridList from '../UI/DisplayImages/ImageGridList';
import ChangeSaveButton from '../UI/Buttons/ChangeSaveButton';
import OutlinedButton from '../UI/Buttons/OutlinedButton';
import DeleteButton from '../UI/Buttons/DeleteButton';
import UpgradeMenu from '../UI/Menus/UIElements/UpgradeMenu';
import { getTaskList, getTaskGroupList, getTaskProps } from '../TaskPlanning/Utility/tasksUtils';
import * as actions from '../../store/actions/index';

// Other stuff
let regex_email = /^\S+@\S+\.\S+$/;

const styles = () => ({
    root: {
        textAlign: 'left',
    },
    textField: {
        width: '100%',
    },
    card: {
        position: 'relative',
        margin: 'auto',
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    tag: {
        margin: '2px 2px',
    },
    inputRoot: {
        '&[class*="MuiInput-root"]': {
            padding: '6px 0px',
            '& $input:first-child': {
                padding: '10px 0px',
                marginBottom: -10
            },
        },
    },
    groupUl: {
        '& $option': {
            paddingLeft: 16,
        },
    },
    option: {
        paddingLeft: 16
    }
});

export class NewNote extends React.Component {
    constructor(props) {
        super(props);
        // Fields are pre-loaded and note is made editable if it
        // is a new one - e.g. accessed without props
        this.state = {
            screenWidth: window.innerWidth,
            open: false,
            title: ("title" in this.props) ? this.props.title : '',
            from: ("from" in this.props) ? this.props.from : '',
            date: ("date" in this.props) ? this.props.date : '',
            text: ("text" in this.props) ? this.props.text : '',
            images: ("images" in this.props) ? this.props.images : [],
            tag: ("tag" in this.props) ? this.props.tag : (this.props.config.hdTag === 'enabled' ? 'privat' : ''),
            todoStatus: ("todoStatus" in this.props) ? this.props.todoStatus : 'Ikke planlagt',
            todoTeam: ("todoTeam" in this.props) ? this.props.todoTeam : null,
            todoEmail: null,
            private: ("private" in this.props) ? this.props.private : true,
            change: ("title" in this.props) ? false : true,
            tasks: ("tasks" in this.props) ? this.props.tasks : [],
            responsible: ("responsible" in this.props) ? this.props.responsible : [],
            taskTypes: ("taskTypes" in this.props) ? this.props.taskTypes : [],
            taskDeadline: ("taskDeadline" in this.props) ? this.props.taskDeadline : null,
            priority: ("priority" in this.props) ? this.props.priority : 9999,
            submit: false,
            newNoteState: this.props.title ? false : true,
            saveLoading: false,
            deleteLoading: false,
            disabled: false,
            tempSelectedForest: this.props.selectedForest,
            unSavedData: false,
            showUnsavedWarning: false,
            showSendEmailWarning: false,
            sendByEmail: false,
            tempErrorSendTodo: null,
            tempLoadingSendTodo: false,
            uniqueListOfUserNames: [],
        };
        this.childHandler = this.childHandler.bind(this)
    }

    componentDidMount() {
        // For new notes set them editable as default
        if (this.state.newNoteState) {
            this.props.onSetNoteEditable(true, 'new')
        }
    }

    componentDidUpdate() {
        // Snackbar info ---------------------------------
        // Check for status after send todo email - removed for HD notes due to unmount proceedure
        if (this.props.errorSendTodo !== null) {
            // Fire snackbar
            let receiver = '';
            if (this.state.sendByEmail) {
                receiver = this.state.todoEmail;
            } else {
                receiver = (this.state.todoTeam && this.state.todoTeam.name) ? this.state.todoTeam.name : this.state.todoEmail;
            }
            this.props.onEnqueueSnackbar({
                message: 'Note kunne ikke sendes til ' + receiver + '! Tjek e-mail eller prøv igen senere.',
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: 'error',
                },
            });
            this.props.onUserResetError();
            this.setState({ sendByEmail: false })
        } else if (this.props.sendTodoSuccess) {
            // Fire snackbar
            let receiver = '';
            if (this.state.sendByEmail) {
                receiver = this.state.todoEmail;
            } else {
                receiver = (this.state.todoTeam && this.state.todoTeam.name) ? this.state.todoTeam.name : this.state.todoEmail;
            }
            this.props.onEnqueueSnackbar({
                message: 'Opgave er sendt til ' + receiver + '!',
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: 'success',
                },
            });
            this.props.onUserResetError();
            this.setState({ sendByEmail: false })
        }

        // Save navigation ---------------------------------
        // disable spinner, navigate back, and show snackbar
        if (this.props.successNote) {
            this.setState({
                change: false,
                saveLoading: false,
                disabled: false
            })

            this.props.onSetNewNoteFinished(true);

            let path = "/"
            if (this.props.location.state.prevPage === "/Notes") {
                path = "/Notes"
            }

            this.props.history.replace({
                pathname: path,
                state: {
                    prevPage: this.props.location.state.prevPage,
                }
            });
        }

        // Delete navigation ---------------------------------
        // disable spinner, navigate back, and show snackbar
        if (this.props.deleteNote) {
            this.setState({
                change: false,
                deleteLoading: false,
                disabled: false
            })
            this.goBackClickHandler();
        }

        // --
        // Handle selected forest change when in inventory
        if (this.state.tempSelectedForest !== this.props.selectedForest) {
            if (this.state.newNoteState) {
                this.setState({ unSavedData: false });
            }

            this.goBackClickHandler()
        }
    }

    componentWillUnmount() {
        // Show snackbar if note is saved
        if (this.props.successNote) {
            this.props.onEnqueueSnackbar({
                message: 'Note er nu gemt!',
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: 'success',
                },
            });
            const eD = {
                event: "Note gemt",
                type: "Note",
                reference: this.state.title,
                private: this.state.private,
                forestName: this.props.customerList[this.props.selectedForest].forestName,
                userId: this.props.currentAuthUser.id,
                userName: this.props.currentAuthUser.name,
            }
            this.props.onAddEventToEventLog(this.props.selectedForest, eD)
        }

        // Show snackbar if note is deleted
        if (this.props.deleteNote) {
            this.props.onEnqueueSnackbar({
                message: 'Note er nu slettet!',
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: 'success',
                },
            });
            const eD = {
                event: "Note slettet",
                type: "Note",
                reference: this.state.title,
                private: this.state.private,
                forestName: this.props.customerList[this.props.selectedForest].forestName,
                userId: this.props.currentAuthUser.id,
                userName: this.props.currentAuthUser.name,
            }
            this.props.onAddEventToEventLog(this.props.selectedForest, eD)
        }
        // If on mobile device close sidebar if note was accessed from map
        if (this.state.screenWidth < 760) {
            if (this.props.location.state.prevPage !== "noteList") {
                this.props.onSidebarOpen(false);
            }
        }
        // Set reducer to initial state
        this.props.onUnmountNote();
        // Make note non-editable again
        this.props.onSetNoteEditable(false, this.props.id);
        this.props.onSetNoteEditableLatLng(null);
        // Onboarding action if active
        if (this.props.onboardingActive && this.props.onboardingState === 'newNoteEnd') {
            this.props.onSetOnboardingState('measIntro');
        }
    }

    handleClose = () => {
        this.setState({
            open: false,
        });
    };

    handleClick = () => {
        this.setState({
            open: true,
        });
    };

    goBackClickHandler = () => {
        if (!this.state.unSavedData) {
            // set delete new marker state, if note is not saved
            if (this.state.newNoteState) {
                this.props.onSetDeleteMarker(true, this.props.latlng);
                this.props.onSetNewNoteFinished(true);
            }

            // Choose which route to go back to
            let path = "/"
            if (this.props.location.state.prevPage === "/Notes") {
                path = "/Notes"

            }
            this.props.history.replace({
                pathname: path,
                state: {
                    prevPage: this.props.location.state.prevPage,
                }
            });
        } else {
            this.setState({ showUnsavedWarning: true })
        }
    }

    ignoreWarningClickHandler = () => {
        // set delete new marker state, if note is not saved
        if (this.state.newNoteState) {
            this.props.onSetDeleteMarker(true, this.props.latlng);
            this.props.onSetNewNoteFinished(true);
        }

        this.setState({ unSavedData: false })
        // Choose which route to go back to
        let path = "/"
        if (this.props.location.state.prevPage === "/Notes") {
            path = "/Notes"
        }
        this.props.history.replace({
            pathname: path,
            state: {
                prevPage: this.props.location.state.prevPage,
            }
        });
    }

    onDeleteClickHandler = () => {
        // Delete note on cloud
        const noteData = {
            id: this.props.id,
            forestId: this.props.forestId,
            images: this.props.images,
        }
        this.props.onDeleteNote(noteData);

        // Send event to Analytics
        ReactGA.event({
            category: 'Note',
            action: 'Note deleted',
            label: `User: ${this.props.currentAuthUser.name}`
        });

        this.setState({
            deleteLoading: true,
            disabled: true,
            unSavedData: false,
        })
    }

    upload = () => {
        // Check if user is free and only upload 1 image if true
        let uploadImages = this.state.images;
        // Limit number of images that can be uploaded based on profile configuration
        if (this.props.config.imageNumber > 0 && this.state.images.length > this.props.config.imageNumber) {
            uploadImages = this.state.images.slice(0, this.props.config.imageNumber);
        }

        // Upload action
        const noteData = {
            title: this.state.title,
            text: this.state.text,
            from: this.props.currentAuthUser.name,
            images: uploadImages,
            tag: this.state.tag,
            todoStatus: this.state.todoStatus,
            todoTeam: this.state.todoTeam,
            tasks: this.state.tasks,
            responsible: this.state.responsible,
            taskTypes: this.state.taskTypes,
            taskDeadline: this.state.taskDeadline,
            private: this.state.private,
            latlng: this.props.noteEditableLatLng ? this.props.noteEditableLatLng : this.props.latlng,
            markerId: this.props.markerId,
            litraNr: this.props.litraNr,
            id: this.props.id,
            date: moment().format(),
            forestId: this.props.selectedForest,
            priority: this.state.priority,
        }
        this.props.onSaveNote(noteData, this.props.selectedForest, 'forest', 'sidebar');
        // Check if responsible is set and is different from person saving the note
        try {
            if (this.state.tag === 'todo' && this.state.responsible.length > 0) {
                // Current user is not responsible
                if (!this.state.responsible.includes(this.props.currentAuthUser.name)) {
                    // Find the responsible users and email them that todo has been saved
                    this.state.responsible.forEach(person => {
                        Object.values(this.props.orgUsers).forEach(orgUser => {
                            if (orgUser.name === person && regex_email.test(orgUser.email)) {
                                this.handleSendTodo(orgUser.email);
                            }
                        })
                    })
                }
            }
        } catch (error) {
            console.warn(error)
        }

        // Send event to Analytics
        ReactGA.event({
            category: 'Note',
            action: 'Note created',
            label: `User: ${this.props.currentAuthUser.name}`
        });
    }

    changeSaveButton = () => {
        // Upload note to cloud 
        if (this.state.change) {
            // Set submit state
            this.setState({
                submit: true
            })

            // Check if note is incomplete.
            //
            // Images: title, images and tag
            // All other tags: title, text, and tag

            // Images
            if (this.state.title !== "" && this.state.images.length > 0 && this.state.tag === "billeder") {
                this.setState({
                    saveLoading: true,
                    disabled: true,
                    submit: false,
                    newNoteState: false,
                })

                this.upload();
            }

            // All other
            if (this.state.title !== "" && this.state.tag !== "") {
                this.setState({
                    saveLoading: true,
                    disabled: true,
                    submit: false,
                    newNoteState: false,
                })

                // HD tag
                if (this.state.tag === 'hededanmark' && !this.state.showSendEmailWarning && this.state.unSavedData) {
                    if (this.props.customerList[this.props.selectedForest].emailForester && regex_email.test(this.props.customerList[this.props.selectedForest].emailForester)) {

                        this.handleSendTodo();
                    } else {
                        // Send email to us
                        let content = {
                            forestId: this.props.selectedForest,
                            userData: this.props.currentAuthUser
                        };
                        this.props.onSendEmail("hdNoEmailforesterSendNote", this.props.currentAuthUser.id, '', content);
                    }
                }

                if (this.state.tag === 'todo' && this.props.taskPlanningFilterState) {
                    this.props.onSetTaskPlanningFilterState(null);
                }

                this.upload();
            }

            // Make note editable
        } else {
            this.props.onSetNoteEditable(true, this.props.id);
            this.setState({
                change: true
            })
        }
    };

    updateImages = (event) => {
        let files = []
        for (var i = 0; i < event.target.files.length; i++) {
            files[i] = event.target.files.item(i);
        }

        // Add new images
        // Check if there are restrictions on number of images
        let newImgArr = this.state.images.concat(files);
        if (this.props.config.imageNumber > 0 && newImgArr.length > this.props.config.imageNumber) {
            newImgArr = newImgArr.slice(0, this.props.config.imageNumber);
        }
        this.setState({ images: newImgArr, unSavedData: true })
    }

    childHandler(dataFromChild) {
        var arr = this.state.images;
        // Delete selected image
        this.setState({
            images: arr.filter(function (item) {
                return item !== dataFromChild
            })
        })
    }

    noteTitle() {
        const { classes } = this.props;
        const { title, submit } = this.state;

        // When note is in editable state 
        if (this.state.change) {
            return (
                <div>
                    <div
                        style={{ display: 'flex', flexDirection: 'row', alignItems: 'top' }}
                    >
                        <IconButton
                            onClick={this.goBackClickHandler}
                            style={{ padding: 3, marginRight: 4, height: 30, width: 30 }}
                        >
                            <GoBackIcon />
                        </IconButton>

                        <Typography
                            variant="h5"
                            gutterBottom
                        >
                            Note
                        </Typography >
                    </div>

                    <div>
                        <TextField
                            id="note-title"
                            label="Titel"
                            className={classes.textField}
                            value={title}
                            onChange={event => this.setState({ title: event.target.value, unSavedData: true })}
                            error={title === "" && submit}
                            disabled={this.state.disabled}
                            margin="normal"
                            required
                        />
                    </div>
                </div>
            );
        }

        // When note is in non-editable state
        return (
            <div
                style={{ display: 'flex', flexDirection: 'row', alignItems: 'top' }}
            >
                <IconButton
                    onClick={this.goBackClickHandler}
                    style={{ padding: 3, marginRight: 4, height: 30, width: 30 }}
                >
                    <GoBackIcon />
                </IconButton>

                <Typography
                    variant="h5"
                    gutterBottom
                >
                    {this.state.title}
                </Typography >
            </div>
        );
    };

    noteDate() {
        const notePrivate = this.state.private;

        // When note is in non-editable state
        if (!this.state.change) {
            return (
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography
                        variant="caption"
                        noWrap
                        component={'span'}
                        style={{ color: '#747474' }}
                        gutterBottom
                    >
                        {moment(this.props.creationDate ? this.props.creationDate : this.state.date).format('DD[-]MM[-]YYYY HH:mm')}
                    </Typography>

                    {notePrivate && <VisibilityOffIcon style={{ marginBottom: -2, height: 16, color: '#747474' }} />}
                    {!notePrivate && <VisibilityIcon style={{ marginBottom: -2, height: 16, color: '#747474' }} />}
                </div>
            )
        }
    }

    noteText() {
        const { classes } = this.props;
        const { text } = this.state;

        // When note is in editable state
        if (this.state.change) {
            return (
                <TextField
                    id="note-text"
                    label="Note"
                    multiline
                    className={classes.textField}
                    value={text}
                    onChange={event => this.setState({ text: event.target.value, unSavedData: true })}
                    // error={text === "" && submit && tag !== 'billeder'}
                    disabled={this.state.disabled}
                    margin="normal"
                />
            );
        }

        // When note is in non-editable state
        return (
            <div>
                <Typography
                    variant="body1"
                    style={{ whiteSpace: 'pre-line', marginBottom: 2 }}
                    gutterBottom
                >
                    {this.state.text}
                </Typography>
            </div>
        );
    };

    noteAuthor() {
        // When note is in non-editable state
        if (!this.state.change) {
            // Get forest name
            let frstName = '';
            if (this.props.customerList) {
                const checkForForestName = this.props.customerList[this.props.forestId];
                frstName = checkForForestName ? checkForForestName.forestName : '';
            }
            return (
                <Typography
                    variant="caption"
                    style={{ color: '#747474' }}
                    gutterBottom
                >
                    {frstName !== '' ? frstName + ", " + this.state.from : this.state.from}
                </Typography>
            )
        }
    }

    noteImages() {
        const { classes } = this.props;
        const { images, change, submit, tag, disabled } = this.state;
        // Set disabled based on image number from config
        let disableImages = false;
        let disableText = null;
        if (this.props.config.imageNumber > 0 && this.state.images.length >= this.props.config.imageNumber) {
            disableImages = true;
        } else if (this.props.networkStatus === 'offline') {
            disableImages = true;
            disableText = "Det er ikke muligt at vedhæfte billeder. Ingen forbindelse til internet.";
        }
        // When note is in non-editable state
        if (change === false && images.length > 0) {
            return (
                <div style={{ paddingTop: 6, paddingBottom: 6 }}>
                    <ImageGridList
                        images={images}
                        change={change}
                    />
                </div>
            );
        }

        // When note is in editable state
        return (
            <div style={{ marginTop: 8 }}>
                <ImageGridList
                    images={images}
                    change={change}
                    action={this.childHandler}
                    disabled={disabled || disableImages}
                    whenChange={this.updateImages.bind(this)}
                    error={submit && change && images.length < 1 && tag === 'billeder'}
                />
                {(change && disableText) &&
                    <Typography
                        variant="caption"
                        className={classes.textField}
                        style={{ color: '#717171' }}
                    >
                        {disableText}
                    </Typography >}
            </div>
        );
    };

    renderTag(config, tag, tagLabel, privateNote, disabled) {
        const { classes } = this.props;
        let tagLabelTest = tagLabel;
        if (tagLabel === "Dalgas") {
            tagLabelTest = "hededanmark";
        }
        if (config) {
            return (
                <Tooltip title={`Her vælger du kategorien ${tagLabel}`} aria-label="Luk sidebaren">
                    <Chip
                        //avatar={<Avatar style={{ backgroundColor: this.props.filterTag === tag ? '#5EBD3E' : '#EEEEEE', color: this.props.filterTag === tag ? 'white' : '#808080' }}>23</Avatar>}
                        onClick={() => this.setState({ tag: tagLabelTest.toLowerCase(), private: privateNote, unSavedData: true })}
                        clickable
                        label={tagLabel}
                        className={classes.chip}
                        color={tag === tagLabelTest.toLowerCase() ? 'primary' : 'default'}
                        variant="outlined"
                        disabled={disabled}
                    />
                    {/* <div>
                        <Button onClick={() => this.handleFilterTagClick(tag)} style={{ color: this.props.filterTag === tag ? '#5EBD3E' : '#808080', marginLeft: 2, paddingLeft: 0, paddingRight: 0 }}>{tagLabel}</Button>
                        <div style={{ height: 2, width: '100%', backgroundColor: this.props.filterTag === tag ? '#41842b' : 'white' }} />
                    </div> */}
                </Tooltip>
            )
        } else {
            return null
        }
    }

    noteTags() {
        const { classes } = this.props;
        const { change, submit, tag, disabled } = this.state;

        // When note is in editable state 
        if (this.state.change) {
            return (
                <div style={{ marginTop: 18 }}>
                    <Typography
                        variant="caption"
                        className={classes.textField}
                        style={{ color: submit && change && tag === '' ? '#E23838' : '#717171' }}
                    >
                        Kategorier *
                    </Typography >
                    <div style={{ marginLeft: -2 }}>
                        {/* New chip setup */}
                        {this.renderTag(this.props.config.todoTag === 'enabled', tag, 'Todo', false, disabled)}
                        {this.renderTag(this.props.config.forestTag === 'enabled', tag, 'Skoven', true, disabled)}
                        {this.renderTag(this.props.config.huntingTag === 'enabled', tag, 'Jagt', true, disabled)}
                        {this.renderTag(this.props.config.imagesTag === 'enabled', tag, 'Billeder', true, disabled)}
                        {this.renderTag(this.props.config.otherTag === 'enabled', tag, 'Andet', true, disabled)}
                        {this.renderTag(this.props.config.privateTag === 'enabled', tag, 'Privat', true, disabled)}
                        {this.renderTag(this.props.config.ownersTag === 'enabled', tag, 'Skovejere', false, disabled)}
                        {this.renderTag(this.props.config.hdTag === 'enabled', tag, 'Dalgas', false, disabled)}
                    </div>
                </div>
            )
        }

        return (
            <div style={{ marginTop: 18 }}>
                <div style={{ marginBottom: 2 }}>
                    <Typography
                        variant="caption"
                        style={{ color: '#717171' }}
                    >
                        Kategori
                    </Typography>
                </div>

                <Chip
                    label={tag === 'hededanmark' ? 'Dalgas' : tag.charAt(0).toUpperCase() + tag.slice(1)}
                    color={'primary'}
                    variant="outlined"
                />
            </div>
        )
    }

    todoStatus() {
        if (this.state.tag === 'todo') {
            // Edit mode
            if (this.state.change) {
                return (
                    <div style={{ marginTop: 4 }}>
                        <FormControl margin="normal" fullWidth required disabled={this.props.config.taskPlanning.underManagementText !== ''}>
                            <InputLabel htmlFor="todo-status">Status</InputLabel>

                            <Select
                                value={this.state.todoStatus}
                                onChange={this.handleSetTodoStatus}
                                inputProps={{
                                    name: 'todo',
                                    id: 'todo-status',
                                }}
                            >
                                <MenuItem info="" value="Ikke planlagt">Ikke planlagt</MenuItem>
                                <MenuItem info="" value="Ikke startet">Ikke startet</MenuItem>
                                <MenuItem info="" value="I gang">I gang</MenuItem>
                                <MenuItem info="" value="Udført">Udført</MenuItem>
                                {this.props.config.taskPlanning.taskArchiveStatus !== 'disabled' && <MenuItem info="" value="Arkiveret">Arkiveret</MenuItem>}
                            </Select>
                        </FormControl>
                        {this.props.config.taskPlanning.underManagementText !== '' && <Typography variant="caption"
                            color="textSecondary">
                            {this.props.config.taskPlanning.underManagementText}
                        </Typography>}
                    </div>
                )
            }

            // Non edit mode
            return (
                <div style={{ marginTop: 18 }}>
                    <Typography
                        variant="caption"
                        style={{ color: '#717171' }}
                    >
                        Status
                    </Typography >

                    <Typography
                        variant="body1"
                        style={{ color: '#41842b' }}
                    >
                        {this.state.todoStatus}
                    </Typography >
                </div>
            )
        } else {
            return null;
        }
    }

    todoEmail() {
        const { classes } = this.props;
        if (this.state.tag === 'todo') {
            // Edit mode
            if (this.state.change) {
                return (
                    <form>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'flex-end', justifyContent: 'space-between' }}>
                            <TextField
                                id="note-email"
                                label="E-mail"
                                className={classes.textField}
                                value={this.state.todoEmail}
                                onChange={event => this.setState({ todoEmail: event.target.value, unSavedData: true })}
                                disabled={this.state.disabled}
                                margin="normal"
                            />
                            <IconButton
                                disabled={!regex_email.test(this.state.todoEmail)}
                                color="primary"
                                style={{ marginBottom: -8, marginLeft: 4 }}
                                onClick={() => this.setState({ showSendEmailWarning: true, sendByEmail: true })}
                            >
                                <SendIcon />
                            </IconButton>
                        </div>
                    </form>
                )
            } else {
                return null;
            }

        }
    }

    todoTeam() {
        const { classes } = this.props;
        let team = false;
        let sortedTeams = [];
        if (this.props.orgTeams && this.props.orgTeams.length > 0) {
            team = true;
            // Sort list of teams
            sortedTeams = this.props.orgTeams.sort((a, b) => b.name.slice(-1) - a.name.slice(-1));
        }

        if (this.state.tag === 'todo') {
            // Edit mode
            if (this.state.change) {
                return (
                    <form>
                        {team && <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'flex-end', justifyContent: 'space-between' }}>
                            <FormControl margin="normal" fullWidth >
                                <InputLabel htmlFor="select-team">Tilknyt hold</InputLabel>
                                <Select
                                    value={this.state.todoTeam && this.state.todoTeam.name ? this.state.todoTeam.name : 'Ikke tildelt'}
                                    onChange={this.handleSelectTeam}
                                    inputProps={{
                                        name: 'team',
                                        id: 'select-team',
                                    }}
                                >
                                    <MenuItem data={null} value="Ikke tildelt"><em>Ikke tildelt</em></MenuItem>
                                    {sortedTeams.map((team, i) => {
                                        return <MenuItem key={i} data={team} value={team.name}>{team.name}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>

                            <IconButton
                                disabled={(this.state.todoTeam === "Ikke tildelt" || this.state.todoTeam === null)}
                                color="primary"
                                style={{ marginBottom: -8, marginLeft: 4 }}
                                onClick={() => this.setState({ showSendEmailWarning: true })}
                            >
                                <SendIcon />
                            </IconButton>
                        </div>}
                    </form>
                )
            } else {
                // Non edit mode 
                if (team) {
                    return (
                        team && <div style={{ marginTop: 18 }}>
                            <Typography
                                variant="caption"
                                style={{ color: '#717171' }}
                            >
                                Hold
                            </Typography >

                            <Typography
                                variant="body1"
                                style={{ color: '#41842b' }}
                            >
                                {this.state.todoTeam && this.state.todoTeam.name ? this.state.todoTeam.name : 'Ikke tildelt'}
                            </Typography >
                        </div>
                    )
                } else {
                    return null;
                }
            }
        }
    }

    todoPersonPicker() {
        const { classes } = this.props;
        const responsible = this.props.orgUsers ? Object.values(this.props.orgUsers) : [{ ...this.props.currentAuthUser }];
        if (this.state.tag === 'todo' && responsible) {
            // Edit mode
            if (this.state.change) {
                return (
                    <div style={{ marginTop: 4 }}>
                        {/* <Autocomplete
                            multiple
                            id="tags-standard-person"
                            options={responsible.map(el => el.uniqueName)}
                            value={this.state.responsible}
                            onChange={(e, v) => this.handleChangeResponsible(e, v)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    label="Ansvarlig(e)"
                                    placeholder="Ansvarlig"
                                />
                            )}
                        /> */}

                        <FormControl margin="normal" fullWidth>
                            <InputLabel id="mutiple-chip-responsible-label">Ansvarlig(e)</InputLabel>

                            <Select
                                labelid="mutiple-chip-responsible-label"
                                id="mutiple-chip-responsible"
                                multiple
                                value={this.state.responsible}
                                onChange={this.handleChangeResponsible}
                                input={<Input id="select-multiple-chip-responsible" />}
                                renderValue={selected => (
                                    <div className={classes.chips}>
                                        {selected.map((value, index) => (
                                            <Chip
                                                key={index}
                                                label={value}
                                                className={classes.chip}
                                                onMouseDown={(event) => { event.stopPropagation(); }}
                                                onDelete={() => this.handleDeleteChip(value, index, 'responsible')}
                                            />
                                        ))}
                                    </div>
                                )}
                            >
                                {responsible.sort((a, b) => a.name.localeCompare(b.name, 'da-DK')).map(user => (
                                    <MenuItem key={user.id} value={user.name}>
                                        {user.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                )
            }

            // None-edit mode
            if (this.state.responsible.length > 0) {
                let names = this.state.responsible.join(", ");

                return (
                    <div style={{ marginTop: 18 }}>
                        <Typography
                            variant="caption"
                            style={{ color: '#717171' }}
                        >
                            Ansvarlig(e)
                        </Typography >

                        <Typography
                            variant="body1"
                            style={{ color: '#41842b' }}
                        >
                            {names}
                        </Typography>
                    </div>
                )
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    todoTaskGroupPicker() {
        const { classes } = this.props;
        // const taskTypes = this.props.orgTaskTypes ? this.props.orgTaskTypes : this.props.defaultTasksTypes;
        const taskTypes = getTaskGroupList();
        if (this.state.tag === 'todo' && taskTypes) {
            // Edit mode
            if (this.state.change) {
                return (
                    <div style={{ marginTop: 4 }}>
                        {/* <Autocomplete
                            multiple
                            id="tags-standard-group"
                            options={taskTypes.map(el => el[0])}
                            value={this.state.taskTypes}
                            onChange={(e, v) => this.handleChangeTaskTypes(e, v)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    label="Gruppe(r)"
                                    placeholder="Gruppe"
                                />
                            )}
                        /> */}
                        <FormControl margin="normal" fullWidth>
                            <InputLabel id="mutiple-chip-usertype-label">Gruppe(r)</InputLabel>

                            <Select
                                labelid="mutiple-chip-usertype-label"
                                id="mutiple-chip-usertype"
                                multiple
                                value={this.state.taskTypes}
                                onChange={this.handleChangeTaskTypes}
                                input={<Input id="select-multiple-chip-usertype" />}
                                renderValue={selected => (
                                    <div className={classes.chips}>
                                        {selected.map((value, index) => (
                                            <Chip
                                                key={index}
                                                label={value}
                                                className={classes.chip}
                                                onMouseDown={(event) => { event.stopPropagation(); }}
                                                onDelete={() => this.handleDeleteChip(value, index, 'taskType')}
                                            />
                                        ))}
                                    </div>
                                )}
                                disabled={this.state.disabled}
                            >
                                {taskTypes.map(type => (
                                    <MenuItem key={type[0]} value={type[0]}>
                                        {type[0]}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                )
            }

            // None-edit mode
            if (this.state.taskTypes.length > 0) {
                let types = this.state.taskTypes.join(", ");

                return (
                    <div style={{ marginTop: 18 }}>
                        <Typography
                            variant="caption"
                            style={{ color: '#717171' }}
                        >
                            Gruppe(r)
                        </Typography >

                        <Typography
                            variant="body1"
                            style={{ color: '#41842b' }}
                        >
                            {types}
                        </Typography>
                    </div>
                )
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    todoTaskPicker() {
        const { classes } = this.props;
        // const tasks = this.props.orgTasks ? this.props.orgTasks : this.props.defaultTasks;
        // Get list of tasks
        const tasks = getTaskList();
        if (this.state.tag === 'todo' && tasks) {
            // Edit mode
            if (this.state.change) {
                return (
                    <div style={{ marginTop: 8, marginBottom: 12 }}>
                        <Autocomplete
                            classes={{
                                tag: classes.tag,
                                inputRoot: classes.inputRoot,
                                groupUl: classes.groupUl,
                                option: classes.option,
                            }}
                            multiple
                            id="tags-standard-task"
                            options={tasks}
                            noOptionsText='Findes ikke'
                            groupBy={(option) => option[1].group}
                            getOptionLabel={(option) => option[0]}
                            getOptionSelected={(option, value) => option[0] === value[0]}
                            value={this.state.tasks.map(el => [el, getTaskProps(el)])}
                            onChange={(e, v) => this.handleChangeTask(e, v)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    label="Opgavetype(r)"
                                    placeholder="Opgave"
                                />
                            )}
                            disabled={this.state.disabled}
                        />
                    </div>
                )
            }

            // None-edit mode
            if (this.state.tasks.length > 0) {
                let tasks = this.state.tasks.join(", ");

                return (
                    <div style={{ marginTop: 18 }}>
                        <Typography
                            variant="caption"
                            style={{ color: '#717171' }}
                        >
                            Opgavetype(r)
                        </Typography >

                        <Typography
                            variant="body1"
                            style={{ color: '#41842b' }}
                        >
                            {tasks}
                        </Typography>
                    </div>
                )
            } else {
                return null;
            }

        } else {
            return null;
        }
    }

    todoDatePicker() {
        if (this.state.tag === 'todo') {
            // Edit mode
            if (this.state.change) {
                return (
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={daLocale}>
                        <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="dd/MM/yyyy"
                            margin="normal"
                            id={"date-picker-inline-1"}
                            label="Skal gøres inden"
                            value={this.state.taskDeadline}
                            onChange={event => { this.setState({ taskDeadline: event ? event.toString() : null }) }}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            keyboardIcon={<DateRangeIcon fontSize="small" style={{ color: '#5EBD3E' }} />}
                            style={{ width: '100%', marginRight: 8 }}
                            disabled={this.state.disabled}
                        />
                    </MuiPickersUtilsProvider>
                )
            }

            // None-edit mode
            if (this.state.taskDeadline) {
                return (
                    <div style={{ marginTop: 18 }}>
                        <Typography
                            variant="caption"
                            style={{ color: '#717171' }}
                        >
                            Skal gøres inden
                        </Typography >

                        <Typography
                            variant="body1"
                            style={{ color: '#41842b' }}
                        >
                            {moment(this.state.taskDeadline).format('DD[-]MM[-]YYYY')}
                        </Typography >
                    </div>
                )
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    handleDeleteChip = (value, index, type) => {
        if (type === 'task') {
            let newTasks = [...this.state.tasks];
            newTasks = newTasks.filter(el => el !== value);
            this.setState({ tasks: newTasks, unSavedData: true });
        } else if (type === 'responsible') {
            let newResponsible = [...this.state.responsible];
            newResponsible = newResponsible.filter(el => el !== value);
            this.setState({ responsible: newResponsible, unSavedData: true });
        } else if (type === 'taskType') {
            let newTaskTypes = [...this.state.taskTypes];
            newTaskTypes = newTaskTypes.filter(el => el !== value);
            this.setState({ taskTypes: newTaskTypes, unSavedData: true });
        }
    }

    handleChangeResponsible = (event) => {
        // Check if task type is represented based on user added
        let chosenTags = [...this.state.taskTypes];
        event.target.value.forEach(el => {
            // find user data
            const u = this.props.orgUsers ? Object.values(this.props.orgUsers).filter(user => user.name === el) : [this.props.currentAuthUser.name];
            const userTags = u[0].userTags ? u[0].userTags : [];
            // If task types from userTags has not been set, set them
            userTags.forEach(el => {
                if (!chosenTags.includes(el)) {
                    chosenTags.push(el);
                }
            })
        })
        this.setState({ responsible: event.target.value, taskTypes: chosenTags, unSavedData: true });
    }

    handleChangeTaskTypes = (event) => {
        this.setState({ taskTypes: event.target.value, unSavedData: true });
    }

    handleChangeTask = (event, value) => {
        this.setState({ tasks: value.map(task => task[0]), unSavedData: true });
    }

    handleSetTodoStatus = (event, item) => {
        this.setState({ todoStatus: event.target.value, unSavedData: true })
    }

    handleSelectTeam = (event, item) => {
        this.setState({ todoTeam: item.props.data, unSavedData: true })
    }

    handleSendTodo = (email) => {
        // Collect data for email
        let teamData = null;
        if (email) {
            teamData = { email: email };
        } else if (this.state.sendByEmail) {
            teamData = { email: this.state.todoEmail };
        } else if (this.props.config.hdTag === 'enabled') {
            if (this.props.customerList[this.props.selectedForest].emailForester && this.props.customerList[this.props.selectedForest].emailForester !== '') {
                teamData = { email: this.props.customerList[this.props.selectedForest].emailForester };
            }
        } else if (this.state.todoTeam && this.state.todoTeam !== "Ikke tildelt") {
            teamData = this.state.todoTeam;
        } else {
            teamData = { email: this.state.todoEmail };
        }

        // Collect data for email
        const todoData = {
            team: teamData,
            forestData: this.props.customerList[this.props.selectedForest],
            sender: this.props.currentAuthUser.name,
            title: this.state.title,
            text: this.state.text === '' ? '(ingen indhold)' : this.state.text,
            todoStatus: this.state.tag === 'hededanmark' ? 'note' : this.state.todoStatus,
            latlng: this.props.latlng,
            withSnackbar: this.state.tag === 'hededanmark' ? false : true,
        }

        // Send email
        this.props.onSendTodoEmail(todoData)

        // Set todo status to in progress if in not started
        if (this.state.todoStatus === 'Ikke startet' || this.state.todoStatus === 'Ikke planlagt') {
            this.setState({ todoStatus: 'I gang' });
        }

        // Send event to Analytics
        ReactGA.event({
            category: 'Note',
            action: `Todo sent to ${todoData.teamData}`,
            label: `User: ${this.props.currentAuthUser.name}`
        });

        this.setState({ showSendEmailWarning: false })
    }

    upgradeButtonHandler = () => {
        // Open account handling in main menu
        this.props.onMainMenuOpen(true);
        this.props.onAccountMenuOpen(true);

        // Send event to analytics
        ReactGA.event({
            category: 'Sidebar',
            action: 'Upgrade button clicked',
            label: `User: ${this.props.currentAuthUser.name}`
        });
    }

    private() {
        // When note is in editable state
        const textToolTip = this.props.currentAuthUser.userRole === 'forester' ? "Fravælg privat hvis du ønsker at dele noten med en tilknyttet skovejer" : "Fravælg privat hvis du ønsker at dele noten med en tilknyttet skovforvalter"
        if (this.state.change) {
            return (
                <FormControlLabel
                    style={{ marginTop: 12 }}
                    control={
                        <Tooltip title={textToolTip} aria-label={textToolTip}>
                            <Checkbox
                                checked={this.state.private}
                                onChange={(e, v) => { this.setState({ private: this.state.private ? false : true, unSavedData: true }) }}
                                disabled={(this.props.userUID !== this.props.currentAuthUser.id) && !this.state.newNoteState}
                                color="primary"
                            />
                        </Tooltip>
                    }
                    label="Privat"
                />
            )
        }
    }

    noteCancelDelete() {
        // When note is in non-editable state
        if (!this.state.change) {

        }

        // When note is in editable state
        // Set action menu items
        const items = [
            {
                name: "Slet",
                text: "Du er nu ved at slette. Ønsker du at fortsætte? Handlingen kan ikke annulleres.",
                onClickHandler: this.onDeleteClickHandler,
                buttonIcon: true
            },
        ]
        // When note is in editable state
        return (
            <DeleteButton
                items={items}
                deleteLoading={this.state.deleteLoading}
                disabled={this.state.disabled || (this.state.tag === 'todo' && this.props.config.taskPlanning.underManagementText !== '')}
            />
        );
    }

    noteChangeSave() {
        // When note is in editfable state
        if (this.state.change) {
            return (
                <div style={{ display: 'flex' }}>
                    <OutlinedButton
                        buttonLabel='Annuller'
                        onPress={this.goBackClickHandler}
                        variant='text'
                        discrete={true}
                    />

                    <ChangeSaveButton
                        change={false}
                        onPress={this.changeSaveButton.bind(this)}
                        saveLoading={this.state.saveLoading}
                        disabled={this.state.disabled}
                    />
                </div>
            );
        }

        // When note is in non-editfable state
        return (
            <ChangeSaveButton
                change={true}
                onPress={this.changeSaveButton.bind(this)}
                saveLoading={this.state.saveLoading}
                disabled={this.state.disabled || this.props.selectedForest !== this.props.forestId}
            />
        );
    }

    renderTips() {
        if (this.state.change && !this.state.newNoteState) {
            return (
                <div style={{ marginTop: 12, marginBottom: 8 }}>
                    <Typography variant="body1" >
                        Tip
                    </Typography >

                    <div style={{ display: 'flex' }}>
                        <Typography variant="body2" style={{ color: '#6A6A6A', marginLeft: 4, marginRight: 8 }} >
                            •
                        </Typography >

                        <Typography variant="body2" style={{ color: '#6A6A6A' }} >
                            Noter i kortet kan flyttes, ved at trække i dem med musen
                        </Typography >
                    </div>
                </div>
            )
        }
    }

    renderUpgrade() {
        if (this.state.change && (this.props.config.imageNumber > 0 && this.state.images.length >= this.props.config.imageNumber)) {
            return (
                <div style={{ marginTop: 12, marginBottom: 8 }}>
                    <UpgradeMenu
                        title='Brug for flere billeder?'
                        content='Du kan vedhæfte ét billede med din nuværende bruger. Har du brug for flere, kan du opgradere dit abonnement her.'
                        cta='Opgrader'
                        onPress={() => this.upgradeButtonHandler()}
                    />
                </div>
            )
        } else {
            return null
        }
    }

    render() {
        const { classes } = this.props;
        const { change, tag, newNoteState } = this.state;
        // Task planning disable email and team if user
        let profileDisable = false;
        if (this.props.currentAuthUser.userTags && !this.props.currentAuthUser.userTags.some(tag => ["Admin", "Skovfoged", "Skovejer"].includes(tag))) {
            profileDisable = true;
        }
        return (
            <div className={classes.root}>
                <Card className={classes.card}>
                    <CardContent>
                        {this.noteTitle()}
                        {this.noteDate()}
                        {tag !== 'billeder' ? this.noteText() : null}
                        {this.noteImages()}
                        {this.renderUpgrade()}
                        {this.renderTips()}
                        {this.noteAuthor()}

                        {this.noteTags()}

                        {(this.props.config.privateSelection === 'enabled' && this.state.tag !== 'privat' && this.state.tag !== 'Skovejere' && this.state.tag !== 'hededanmark') && this.private()}

                        {this.props.config.taskPlanning.taskType === 'enabled' && this.todoTaskPicker()}

                        {this.props.config.taskPlanning.taskResponsible === 'enabled' && this.todoPersonPicker()}

                        {this.props.config.taskPlanning.taskGroup === 'enabled' && this.todoTaskGroupPicker()}

                        {this.props.config.taskPlanning.taskDate === 'enabled' && this.todoDatePicker()}

                        {this.todoStatus()}

                        {(this.props.config.todoEmail === 'enabled' && !profileDisable) && this.todoEmail()}

                        {(this.props.config.todoTeams === 'enabled' && !profileDisable) && this.todoTeam()}
                    </CardContent>

                    <CardActions>
                        {this.noteChangeSave()}
                        {!newNoteState && change && this.noteCancelDelete()}
                    </CardActions>
                </Card>

                <Dialog open={this.state.showUnsavedWarning}>
                    <DialogTitle>
                        OBS
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Der findes ændringer, som endnu ikke er gemte.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
                            <OutlinedButton
                                buttonLabel='Tilbage'
                                onPress={() => this.setState({ showUnsavedWarning: false })}
                                variant='text'
                                discrete={true}
                            />
                            <OutlinedButton
                                buttonLabel='Ændringer skal ikke gemmes'
                                buttonIcon={<IconChevronRight />}
                                iconLeft={false}
                                onPress={this.ignoreWarningClickHandler}
                                color='primary'
                            />
                        </div>
                    </DialogActions>
                </Dialog>

                <Dialog open={this.state.showSendEmailWarning}>
                    <DialogTitle>
                        OBS
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Du er ved at sende en e-mail tilhørende noten.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
                            <OutlinedButton
                                buttonLabel='Tilbage'
                                onPress={() => this.setState({ showSendEmailWarning: false })}
                                variant='text'
                                discrete={true}
                            />

                            <OutlinedButton
                                buttonLabel='Send e-mail'
                                buttonIcon={<IconChevronRight />}
                                iconLeft={false}
                                onPress={() => this.handleSendTodo()}
                                color='primary'
                            />
                        </div>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
};

const mapStateToProps = state => {
    return {
        networkStatus: state.layout.networkStatus,
        successNote: state.notes.successNote,
        deleteNote: state.notes.deleteNote,
        currentAuthUser: state.user.currentAuthUser,
        onboardingActive: state.onboarding.onboardingActive,
        onboardingState: state.onboarding.onboardingState,
        selectedForest: state.customer.currentlySelectedForestId,
        sidebar: state.layout.sidebarOpen,
        customerList: state.customer.customerList,
        orgTeams: state.org.orgTeams,
        loadingSendTodo: state.user.loadingSendTodo,
        errorSendTodo: state.user.errorSendTodo,
        sendTodoSuccess: state.user.sendTodoSuccess,
        operationActive: state.account.operationActive,
        orgUsers: state.org.orgUsers,
        orgTasks: state.org.orgTasks,
        orgTaskTypes: state.org.orgTaskTypes,
        noteEditableLatLng: state.notes.noteEditableLatLng,
        defaultTasks: state.activity.defaultTasks,
        defaultTasksTypes: state.activity.defaultTasksTypes,
        taskPlanningFilterState: state.activity.filterState,
        config: state.appConfig.newNote,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onSaveNote: (noteData, forestId, layerType) => dispatch(actions.saveNote(noteData, forestId, layerType)),
        onEnqueueSnackbar: (message, options) => dispatch(actions.enqueueSnackbar(message, options)),
        onUnmountNote: () => dispatch(actions.unmountNote()),
        onDeleteNote: (noteData) => dispatch(actions.deleteNote(noteData)),
        onSetDeleteMarker: (s, latlng) => dispatch(actions.setDeleteMarker(s, latlng)),
        onSetNewNoteFinished: (s) => dispatch(actions.setNewNoteFinished(s)),
        onSetNoteEditable: (s, id) => dispatch(actions.setNoteEditable(s, id)),
        onSetNoteEditableLatLng: (id) => dispatch(actions.setNoteEditableLatLng(id)),
        onSetOnboardingState: (s) => dispatch(actions.setOnboardingState(s)),
        onSidebarOpen: (state) => dispatch(actions.sidebarOpen(state)),
        onAddEventToEventLog: (forestId, data) => dispatch(actions.addEventToEventLog(forestId, data)),
        onSendTodoEmail: (todoData) => dispatch(actions.sendTodoEmail(todoData)),
        onSendEmail: (type, userId, customerEmail, content) => dispatch(actions.sendEmail(type, userId, customerEmail, content)),
        onUserResetError: () => dispatch(actions.userResetError()),
        onMainMenuOpen: (state) => dispatch(actions.mainMenuOpen(state)),
        onAccountMenuOpen: (state) => dispatch(actions.accountMenuOpen(state)),
        onSetTaskPlanningFilterState: (s) => dispatch(actions.setActivityFilterState(s)),
    };
}

NewNote.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(NewNote)));