import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReactGA from 'react-ga';
import MediaQuery from 'react-responsive';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeftRounded';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import HowToVoteIcon from '@material-ui/icons/HowToVoteRounded';
// import GamesIcon from '@material-ui/icons/GamesRounded';
import ExitToAppIcon from '@material-ui/icons/ExitToAppRounded';
import ResetPasswordIcon from '@material-ui/icons/VpnKeyRounded';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Tooltip from '@material-ui/core/Tooltip';

// Icons
import MenuIcon from '@material-ui/icons/MenuRounded';
import HDLogo from './Dalgas-main-menu-icon.png'

// Components
import Profile from './Profile';
import Contact from './Contact';
import Subscription from './Subscription/Subscription2';
import IntegrationMain from './Integration/IntegrationMain';
import ExportMenu from './Export/ExportMain';
import Settings from './Settings';
import currentSoftwareVersion from '../../../../backend/currentSoftwareVersion';
import Terms from '../../../Terms/Terms';

// Configuration
import env from '../../../../backend/buildConfig';
// Store
import * as actions from '../../../../store/actions/index';

// Games
import ColorFlood from '../../../Games/ColorFlood/ColorFlood';

const styles = () => ({
    button: {
        position: 'absolute',
        left: '10px',
        top: '10px',
        backgroundColor: 'white',
        '&:hover': {
            backgroundColor: 'white',
        },
    },
    drawer: {
        flexShrink: 0,
        paddingLeft: 16,
        paddingRight: 16,
    },
    drawerPaper: {
        maxWidth: 370,
    },
    versionTxt: {
        paddingTop: 6,
        paddingLeft: 16,
        paddingRight: 16,
        color: 'black',
    },
    logo: {
        height: 36,
    },
})

class MainMenu extends React.Component {
    state = {
        open: false,
        openGame: false,
        openTerms: false,
        loadingTerms: false,
    };

    componentDidMount() {
        if (this.props.currentAuthUser && !this.props.currentAuthUser.acceptedTerms) {
            this.setState({ openTerms: true })
        }
    }

    componentWillUpdate() {
        if (this.props.successUser && !this.state.snackbarState) {
            // Initiate animation of the content section to move for snackbar
            this.setState({ snackbarState: true });
        }
    }

    componentDidUpdate() {
        if (this.state.loadingTerms && !this.props.userLoading) {
            this.setState({ loadingTerms: false, openTerms: false });
        }
    }

    handleDrawerOpen = () => {
        this.props.onMainMenuOpen(true);

        // Send event to Analytics
        ReactGA.event({
            category: 'Main menu open',
            action: `Main menu open: ${true}`,
            label: `User: ${this.props.currentAuthUser.name}`
        });
    }

    handleDrawerClose = () => {
        this.props.onMainMenuOpen(false);

        // Send event to Analytics
        ReactGA.event({
            category: 'Main menu open',
            action: `Main menu open: ${false}`,
            label: `User: ${this.props.currentAuthUser.name}`
        });
    }

    handleClickOpen = () => {
        this.setState({ openGame: true });

        // Send event to Analytics
        ReactGA.event({
            category: 'Game page',
            action: `Game page open: ${true}`,
            label: `User: ${this.props.currentAuthUser.name}`
        });
    };

    handleClose = () => {
        this.setState({ openGame: false });

        // Send event to Analytics
        ReactGA.event({
            category: 'Game page',
            action: `Game page open: ${false}`,
            label: `User: ${this.props.currentAuthUser.name}`
        });
    };

    handleClickTerms = () => {
        this.setState({ openTerms: !this.state.openTerms })
    }

    handleAcceptTerms = () => {
        this.props.onSetAcceptTermsState(true);
        this.setState({ loadingTerms: true })
    }

    newWish = () => {
        // Open sideBar
        if (!this.props.sidebarOpen) {
            this.props.onSidebarOpen(true);
        }
        // Navigate to new feature request page
        this.props.history.replace({
            pathname: '/overviewMapNewFeatures',
        });
    }

    signOut = () => {
        // Signout user
        this.props.onSignOutUser();
        window.location.reload();
    }

    resetPassword = () => {
        this.props.onResetPassword(this.props.currentAuthUser.email)

        this.props.onEnqueueSnackbar({
            message: 'Email sendt!',
            options: {
                key: new Date().getTime() + Math.random(),
                variant: 'success',
            },
        });
    }

    renderProfile() {
        if (this.props.currentAuthUser !== null) {
            const { address, city, email, forest, name, zip } = this.props.currentAuthUser;
            let tempOrg = '';
            if (this.props.org !== null) {
                tempOrg = this.props.org;
            }
            return (
                <Profile mainMenuOpen={this.props.mainMenuOpen} address={address} city={city} email={email} forest={forest} org={tempOrg} name={name} zip={zip} />
            );
        }
        return (
            <div />
        )
    }


    render() {
        const { classes } = this.props;

        // Enable change password
        let enableChangePass = true;
        if (this.props.user.providerData[0].providerId !== "password") {
            enableChangePass = false;
        }

        // Set main menu logo based on profile
        let menuLogo = <MenuIcon style={{ color: 'white' }} />;
        if (this.props.config.iconPath !== "") {
            menuLogo = <div style={{ display: 'flex', justifyContent: 'center' }}>
                <img
                    src={HDLogo}
                    alt={'HD-logo'}
                    className={classes.logo}
                />
            </div>
        }

        // Change button color when disabling during onboarding
        const buttonColor = this.props.onboardingActive ? '#669c55' : '#41842b';

        // Enable integration
        // const enableInt = this.props.kwIntegrationActive || (this.props.currentAuthUser.packages && this.props.currentAuthUser.packages.includes('kwIntegration'));

        return (
            <div>
                <Tooltip title="Hovedmenu" aria-label="Hovedmenu">
                    <Fab
                        aria-label="Menu"
                        className={classes.button}
                        onClick={this.handleDrawerOpen}
                        disabled={this.props.onboardingActive}
                        style={{
                            position: 'absolute',
                            left: '10px',
                            top: '10px',
                            backgroundColor: buttonColor,
                            zIndex: 1
                        }}
                        size={this.props.mobile ? 'medium' : 'large'}
                    >
                        {menuLogo}
                    </Fab>
                </Tooltip>

                <Drawer
                    className={classes.drawer}
                    variant="persistent"
                    anchor="left"
                    open={this.props.mainMenuOpen}
                    disabled={this.onboardingActive}
                    classes={{
                        paper: classes.drawerPaper
                    }}
                >
                    <div>
                        <div
                            style={{ display: 'flex', flexDirection: 'row', alignItems: 'top', marginTop: 17, marginLeft: 10, height: 38 }}
                        >
                            <IconButton
                                onClick={this.handleDrawerClose}
                                style={{ padding: 3, marginRight: 4, height: 30, width: 30 }}
                            >
                                <ChevronLeftIcon />
                            </IconButton>

                            <Typography
                                variant="h5"
                                gutterBottom
                            >
                                Hovedmenu
                            </Typography >
                        </div>

                        <div>
                            <Divider />

                            {this.renderProfile()}

                            <Divider />

                            {this.props.config.subscription.display === 'enabled' && <Subscription />}
                            {this.props.config.subscription.display === 'enabled' && <Divider />}

                            <Settings />

                            <Divider />

                            {this.props.config.integration !== "hidden" && <IntegrationMain />}
                            {this.props.config.integration !== "hidden" && <Divider />}

                            {this.props.config.export !== "hidden" && <ExportMenu />}
                            {this.props.config.export !== "hidden" && <Divider />}

                            <Contact />

                            <Divider />

                            <List>
                                <MediaQuery minDeviceWidth={760}>
                                    <ListItem
                                        button
                                        key='Nyt ønske'
                                        onClick={this.newWish}
                                    >
                                        <ListItemIcon>
                                            <HowToVoteIcon />
                                        </ListItemIcon>
                                        <ListItemText primary='Nyt ønske' />
                                    </ListItem>


                                    {/* <ListItem
                                        button
                                        key='Hemmeligt spil'
                                        onClick={this.handleClickOpen}
                                    >
                                        <ListItemIcon>
                                            <GamesIcon />
                                        </ListItemIcon>
                                        <ListItemText primary='Hemmeligt spil' />
                                    </ListItem> */}
                                </MediaQuery>

                                {enableChangePass && <ListItem
                                    button
                                    key='Ændre adgangskode'
                                    onClick={this.resetPassword}
                                >
                                    <ListItemIcon>
                                        <ResetPasswordIcon />
                                    </ListItemIcon>
                                    <ListItemText primary='Ændre adgangskode' />
                                </ListItem>}

                                <ListItem
                                    button
                                    key='Log ud'
                                    onClick={this.signOut}
                                >
                                    <ListItemIcon>
                                        <ExitToAppIcon />
                                    </ListItemIcon>
                                    <ListItemText primary='Log ud' />
                                </ListItem>
                            </List>
                        </div>
                    </div>

                    <Divider />

                    <Typography
                        variant="caption"
                        gutterBottom
                        className={classes.versionTxt}
                        style={{ marginTop: 16 }}
                    >
                        Værktøjer: <br />
                        <a style={{}} href="https://leafletjs.com/" target="_blank" rel="noopener noreferrer">Leaflet</a>, <a style={{}} href="https://www.google.dk/maps" target="_blank" rel="noopener noreferrer">Google Maps</a>, <a style={{}} href="https://www.openstreetmap.org" target="_blank" rel="noopener noreferrer">OpenStreetMap</a>
                    </Typography>

                    <Typography
                        variant="caption"
                        className={classes.versionTxt}
                        gutterBottom
                    >
                        Datakilder: <br />
                        <a style={{}} href="https://datafordeler.dk" target="_blank" rel="noopener noreferrer">Datafordeleren</a>, <a style={{}} href="https://kortforsyningen.dk/indhold/vilkaar-og-betingelser" target="_blank" rel="noopener noreferrer">Kortforsyningen</a>, <a style={{}} href="https://miljoeportal.dk/dataansvar/vilkaar-for-brug" target="_blank" rel="noopener noreferrer">Danmarks Miljøportal</a>, <a style={{}} href="https://slks.dk" target="_blank" rel="noopener noreferrer">Slots- og Kulturstyrrelsen</a>, <a style={{}} href="https://lbst.dk" target="_blank" rel="noopener noreferrer">Landbrugsstyrelsen</a>
                    </Typography>

                    <Typography
                        variant="caption"
                        gutterBottom
                        className={classes.versionTxt}
                    >
                        Software version: <br />
                        {env.stage === 'development' ? 'Dev: ' + currentSoftwareVersion.vers : currentSoftwareVersion.vers}
                    </Typography>

                    <Typography
                        variant="caption"
                        gutterBottom
                        className={classes.versionTxt}
                    >
                        Brugervilkår og betingelser: <br />
                    </Typography>

                    <div
                        onClick={this.handleClickTerms}
                        style={{ margin: '0px 16px 20px 16px', fontSize: 12, cursor: 'pointer', textDecoration: 'underline' }}
                    >
                        Brugervilkår og betingelser - Version 1.3
                    </div>
                </Drawer>

                <Terms
                    terms={this.props.terms}
                    openTerms={this.state.openTerms}
                    handleTermsClose={this.handleClickTerms}
                    showAcceptButton={!this.props.currentAuthUser.acceptedTerms}
                    showCloseButton={this.props.currentAuthUser.acceptedTerms}
                    handleAcceptTerms={this.handleAcceptTerms}
                    loading={this.state.loadingTerms}
                />

                <Dialog
                    fullScreen={false}
                    open={this.state.openGame}
                    onClose={this.handleClose}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogContent>
                        <Typography
                            variant="body1"
                            gutterBottom
                        >
                            En lille overraskelse fra os bag Skovkortet til dig. Nyd en velfortjent pause med et spil Color Flood 😁 Se hvor mange tryk du skal bruge på at dække pladen med kun én farve. Spillet starter i venstre hjørne i toppen - brug knapperne nedenfor 👇
                        </Typography>
                        <ColorFlood />
                    </DialogContent>
                </Dialog>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        terms: state.user.userTerms,
        userLoading: state.user.loading,
        user: state.user.User,
        currentAuthUser: state.user.currentAuthUser,
        successUser: state.user.successUser,
        onboardingActive: state.onboarding.onboardingActive,
        org: state.org.org,
        reepayState: state.account.reepayState,
        kwIntegrationActive: state.account.kwIntegrationActive,
        sidebarOpen: state.layout.sidebarOpen,
        mainMenuOpen: state.layout.mainMenuOpen,
        config: state.appConfig.mainMenu,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onSignOutUser: () => dispatch(actions.signOutUser()),
        onResetPassword: (email) => dispatch(actions.resetPassword(email)),
        onSetAcceptTermsState: (state) => dispatch(actions.setAcceptTermsState(state)),
        onEnqueueSnackbar: (message, options) => dispatch(actions.enqueueSnackbar(message, options)),
        onSidebarOpen: (state) => dispatch(actions.sidebarOpen(state)),
        onMainMenuOpen: (state) => dispatch(actions.mainMenuOpen(state)),
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MainMenu)));