import * as actionTypes from './actionTypes';
import firebase from 'firebase';
// import mailchimp from "@mailchimp/mailchimp_marketing";
import configMailChimp from '../../backend/configMailChimp';
import configGetStream from '../../backend/configGetStream';
import moment from 'moment';

// Third party sign in
const signInProvider = new firebase.auth.OAuthProvider('microsoft.com');
signInProvider.setCustomParameters({
   // Force re-consent.
   // prompt: 'consent',
});

// Other packages
var stream = require('getstream');

// Setup mailchimp
// mailchimp.setConfig({
//    apiKey: configMailChimp.apiKey,
//    server: configMailChimp.server,
// });

export const authUser = (user) => {
   return {
      type: actionTypes.AUTH_USER,
      user: user
   };
};

export const currentUser = (currentAuthUser) => {
   return {
      type: actionTypes.CURRENT_USER,
      currentAuthUser: currentAuthUser
   }
}

export const noAuthUser = () => {
   return {
      type: actionTypes.NO_AUTH_USER,
      user: false
   }
}

export const signInStarted = () => {
   return {
      type: actionTypes.SIGN_IN_STARTED
   }
}

export const loginFailed = (errorCode) => {
   return {
      type: actionTypes.LOGIN_FAILED,
      errorCode: errorCode
   }
}

export const setUpdateUserProfile = (state) => {
   return {
      type: actionTypes.SET_UPDATE_USER_PROFILE,
      state: state
   }
}

export const userResetError = () => {
   return {
      type: actionTypes.USER_RESET_ERROR,
   }
}

export const unmountSaveUserProfile = () => {
   return {
      type: actionTypes.UNMOUNT_SAVE_USER,
   };
};

export const checkOfAuth = () => async (dispatch) => {
   var db = firebase.firestore();

   try {
      firebase.auth().onAuthStateChanged(function (user) {
         if (user) {
            // User is signed in.
            // Get user
            db.collection("users").doc(user.uid)
               .onSnapshot(function (doc) {
                  dispatch(setUpdateUserProfile(true));
                  // Dispatch current auth user
                  dispatch(currentUser(doc.data()));
               });

            // Dispatch auth user state
            dispatch(authUser(user));
         } else {
            // No user is signed in.
            // Dispatch no auth user
            dispatch(noAuthUser());
         }
      });
   } catch (error) {
      console.error('Error :', error);
   }
}

export const signInUser = (email, password) => {
   return dispatch => {
      dispatch(signInStarted());

      firebase.auth().signInWithEmailAndPassword(email, password).then(
         userAcceptedTerms()
      ).catch(function (error) {
         // Handle Errors here.
         var errorCode = error.code;
         dispatch(loginFailed(errorCode));
      });
   };
};

export const signInUserThirdParty = (additionalData) => async (dispatch) => {
   var db = firebase.firestore();
   dispatch(signInStarted());
   try {
      if (additionalData.email && additionalData.email !== '') {
         signInProvider.setCustomParameters({
            login_hint: additionalData.email
         });
      }
      const result = await firebase.auth().signInWithPopup(signInProvider);
      const uid = result.user.uid;
      const userRef = await db.collection("users").doc(uid).get();
      // Create user if it does not exist
      if (!userRef.exists) {
         let stopCreateUser = false;
         // Create user if login from hededanmark page and user dosen't exist
         if (additionalData.originUrl === 'hededanmark' || additionalData.originUrl === 'opret-konto') {
            // Check for external affiliation
            let affData = null;
            if (additionalData.originUrl === 'hededanmark') {
               affData = await getAffiliationData(result.user.email);
               if (affData) {
                  affData.forEach(el => {
                     if (el.aff.name === 'HD' && el.userData.length === 0) {
                        stopCreateUser = true;
                        const tempId = "User. id: " + uid + ". Email: " + (result.user && result.user.email) ? result.user.email : "NA";
                        // Sen email to us
                        sendUserSignUpErrorEmail(tempId, "Save user data, set affiliation", {
                           message: "The affiliation was found, but there where no user data associated to it. We have thrown an error message for the user to contact HedeDanmark",
                           data: additionalData,
                           affData: affData
                        }
                        )
                        dispatch(loginFailed("auth/no-hd-user-affiliation-data-available"));
                     }
                  })
               }
            }
            if (stopCreateUser) {
               // Delete user again and send error that user dosen't exist
               const user = firebase.auth().currentUser;
               try {
                  await user.delete()
               } catch (error) {
                  console.error("Delete user failed", error)
               }
            } else {
               // Form user data based on third party and additional obtained data
               let name = result.user.email;
               let phone = "";
               if (result.additionalUserInfo && result.additionalUserInfo.profile) {
                  if (result.additionalUserInfo.profile.displayName && typeof result.additionalUserInfo.profile.displayName === "string") {
                     name = result.additionalUserInfo.profile.displayName;
                  }
                  if (result.additionalUserInfo.profile.mobilePhone) phone = result.additionalUserInfo.profile.mobilePhone;
               }
               const userData = {
                  email: result.user.email,
                  name: name,
                  userRole: additionalData.userRole ? additionalData.userRole : 'forestOwner',
                  forestName: additionalData.forestName ? additionalData.forestName : '',
                  phone: phone,
                  city: additionalData.city ? additionalData.city : '',
                  address: additionalData.address ? additionalData.address : '',
                  zip: additionalData.zip ? additionalData.zip : '',
                  org: additionalData.org ? additionalData.org : '',
                  cvr: additionalData.cvr ? additionalData.cvr : '',
                  packages: additionalData.packages ? additionalData.packages : [],
                  acceptedTerms: additionalData.acceptedTerms ? additionalData.acceptedTerms : false,
                  signUpObject: additionalData.signUpObject,
               }
               // Call create user with uid and data object
               dispatch(createNewUser(userData, uid, affData));
            }
         } else {
            // Delete user again and send error that user dosen't exist
            const user = firebase.auth().currentUser;
            try {
               await user.delete()
            } catch (error) {
               console.error("Delete user failed", error)
            }
            dispatch(loginFailed("auth/third-party-user-not-found"));
         }
      } else {
         // Check if user should be updated with new affiliation data
         const userData = userRef.data();
         if (additionalData.originUrl === 'hededanmark' && !userData.affiliation.HD) {
            // Upate user
            // Check for external affiliation
            let affData = null;
            affData = await getAffiliationData(result.user.email);
            if (affData) {
               affData.forEach(el => {
                  if (el.aff.name === 'HD' && el.userData.length === 0) {
                     // Sen email to us
                     sendUserSignUpErrorEmail(uid, "Save existing (email, password) user third party data, set affiliation", {
                        message: "The affiliation was found, but there where no user data associated to it. We still updated the user!",
                        data: additionalData,
                        affData: affData
                     })
                     dispatch(loginFailed("auth/no-hd-user-affiliation-data-available"));
                     // dispatch(signOutUser());
                  }
               })
            }
            // Update user
            dispatch(updateUserDataSignUp(userData, affData));
         }
      }
   } catch (error) {
      // Handle error.
      console.error("ERROR in update user at MS sign in", error)
      dispatch(loginFailed(error.code));
   }
}

const getAffiliationData = async (email) => {
   const db = firebase.firestore();
   return new Promise(async function (resolve, reject) {
      try {
         let foundAff = [];
         let foundUsers = [];
         const affDB = await db.collection("affiliations").get();
         affDB.forEach(async aff => {
            const affUserData = getSingleAffUserData(aff.id, email);
            foundAff.push(aff.data());
            foundUsers.push(affUserData);
         })
         Promise.all(foundUsers).then(val => {
            let returnData = [];
            foundAff.forEach((aff, i) => {
               if (val[i].length > 0) {
                  returnData.push({ aff: aff, userData: val[i] });
               }
            })
            resolve(returnData);
         })
      } catch (error) {
         reject(error)
      }
   })
}

const getSingleAffUserData = async (affId, email) => {
   const db = firebase.firestore();
   const sEmail = email.toLowerCase();
   return new Promise(async function (resolve, reject) {
      try {
         let foundAff = [];
         const affUserRefs = await db.collection("affiliations").doc(affId).collection("users").where('email', '==', sEmail).get();
         affUserRefs.forEach(affUser => {
            foundAff.push(affUser.data());
         })
         resolve(foundAff);
      } catch (error) {
         reject(error)
      }
   })
}

// map blok id list to forest ids
const mapBlokIdListToForestIds = async (blok_id_list) => {
   const db = firebase.firestore();
   return new Promise(async function (resolve, reject) {
      // Get forest associated to blok id list
      try {
         const forestRefs = await db.collection('forests').where('HD_blok_id', 'in', blok_id_list).get();
         let forestIds = [];
         forestRefs.forEach(forest => {
            forestIds.push({ id: forest.data().id, HD_blok_id: forest.data().HD_blok_id });
         })
         resolve(forestIds);
      } catch (error) {
         reject(error);
      }
   })
}

// Map skovdyrkerne id to forest id
const mapSkovdyrkerneForestIdToForestId = async (sdForestIdList) => {
   const db = firebase.firestore();
   return new Promise(async function (resolve, reject) {
      // Get forest associated to blok id list
      try {
         const forestRefs = await db.collection('forests').where('SD_forest_id', 'in', sdForestIdList).get();
         let forestIds = [];
         forestRefs.forEach(forest => {
            forestIds.push({ id: forest.data().id, SD_forest_id: forest.data().SD_forest_id });
         })
         resolve(forestIds);
      } catch (error) {
         reject(error);
      }
   })
}

export const signOutUser = () => {
   return dispatch => {
      firebase.auth().signOut()
         .then(function () {
            // Sign-out successful
            dispatch(noAuthUser());
         })
         .catch(function (error) {
            // An error happened during signout
            console.error('Error while signout ', error);
         });
   }
}

export const userOnboarded = (userProfile) => async (dispatch) => {
   var user = firebase.auth().currentUser;
   var db = firebase.firestore();

   try {
      const refId = db.collection("users").doc(user.uid);
      refId.update({
         onboarded: userProfile.onboarded,
      });
   } catch (error) {
      console.error('Error: ', error);
   }
}

export const unmountSaveUser = () => {
   return dispatch => {
      dispatch(unmountSaveUserProfile());
   }
}

export const fetchTerms = () => async (dispatch) => {
   var db = firebase.firestore();
   // Get user terms
   db.collection("terms").doc("rev4").get()
      .then(doc => {
         if (!doc.exists) {
         } else {
            dispatch(fetchTermsSuccess(doc.data().text))
         }
      })
      .catch(err => {
         console.error('Error fetching terms', err);
      });
};

export const fetchTermsSuccess = (terms) => {
   return {
      type: actionTypes.FETCH_TERMS_SUCCESS,
      userTerms: terms,
   };
};

const updateUserDataStart = () => {
   return {
      type: actionTypes.UPDATE_USER_DATA_START,
   };
}

const updateUserDataFail = (error) => {
   return {
      type: actionTypes.UPDATE_USER_DATA_FAIL,
      error: error
   };
}

const updateUserDataSuccess = () => {
   return {
      type: actionTypes.UPDATE_USER_DATA_SUCCESS,
   };
}

export const updateUserData = (data, id) => async (dispatch) => {
   const user = firebase.auth().currentUser;
   const db = firebase.firestore();
   let uid = id ? id : user.uid;

   dispatch(updateUserDataStart());
   try {
      const refId = db.collection("users").doc(uid);
      await refId.update({
         ...data
      });
      dispatch(updateUserDataSuccess())
   } catch (error) {
      dispatch(updateUserDataFail(error))
   }
}

// Signup reason
const setSignupReasonStart = () => {
   return {
      type: actionTypes.SET_SIGNUP_REASON_START,
   };
}

const setSignupReasonFail = (error) => {
   return {
      type: actionTypes.SET_SIGNUP_REASON_FAIL,
      error: error
   };
}

const setSignupReasonSuccess = () => {
   return {
      type: actionTypes.SET_SIGNUP_REASON_SUCCESS,
   };
}

export const setSignupReason = (reason) => async (dispatch) => {
   const user = firebase.auth().currentUser;
   const db = firebase.firestore();

   dispatch(setSignupReasonStart());
   try {
      const refId = db.collection("signupReasons").doc();
      await refId.set({
         id: refId.id,
         userId: user.uid,
         reason: reason,
      });
      dispatch(sendEmail("signupReason", user.uid, "", { reason: reason }))
      dispatch(setSignupReasonSuccess())
   } catch (error) {
      console.error('Error: ', error);
      dispatch(setSignupReasonFail(error))
   }
}

// Accept terms
const setAcceptTermsStateStart = () => {
   return {
      type: actionTypes.SET_ACCEPT_TERMS_STATE_START,
   };
}

const setAcceptTermsStateFail = (error) => {
   return {
      type: actionTypes.SET_ACCEPT_TERMS_STATE_FAIL,
      error: error
   };
}

const setAcceptTermsStateSuccess = () => {
   return {
      type: actionTypes.SET_ACCEPT_TERMS_STATE_SUCCESS,
   };
}

export const setAcceptTermsState = (state) => async (dispatch) => {
   const user = firebase.auth().currentUser;
   const db = firebase.firestore();

   dispatch(setAcceptTermsStateStart());
   try {
      const refId = db.collection("users").doc(user.uid);
      await refId.update({
         acceptedTerms: state,
      });
      dispatch(setAcceptTermsStateSuccess())
   } catch (error) {
      console.error('Error: ', error);
      dispatch(setAcceptTermsStateFail(error))
   }
}

export const userAcceptedTerms = () => async (dispatch) => {
   var user = firebase.auth().currentUser;
   var db = firebase.firestore();

   try {
      const refId = db.collection("users").doc(user.uid);
      refId.update({
         acceptedTerms: true,
      });
   } catch (error) {
      console.error('Error: ', error);
   }
}

export const resetPassword = (email) => async (dispatch) => {
   var auth = firebase.auth();

   auth.sendPasswordResetEmail(email).then(function () {
      // Email sent.
   }).catch(function (error) {
      // An error happened.
   });
}

const createNewUserStart = () => {
   return {
      type: actionTypes.CREATE_NEW_USER_START,
   };
}

const createNewUserFail = (userId, step, error) => async (dispatch) => {
   // Create error message
   let msg = 'Unknown error message';
   if (typeof error === "string") {
      msg = error;
   } else if (typeof error.code !== undefined) {
      msg = error.code;
   }

   sendUserSignUpErrorEmail(userId, step, error);
   dispatch(createNewUserFailDispatch(msg))
}

export const createNewUserFailDispatch = (msg) => {
   return {
      type: actionTypes.CREATE_NEW_USER_FAIL,
      error: msg,
   };
}

const createNewUserSuccess = () => {
   return {
      type: actionTypes.CREATE_NEW_USER_SUCCESS,
   };
}

export const createNewUser = (data, uid, affData) => async (dispatch) => {
   dispatch(createNewUserStart())
   let token = null;
   let userData = null;
   let signUpObject = data.signUpObject ? data.signUpObject : {};
   let userId = uid ? uid : null;
   let step = 'Create user start';
   let affDataUp = affData;

   // Begin create new user
   try {
      // Check for user id - is there when using third party signon
      if (!userId) {
         // create Firebase user based on email and password
         try {
            step = 'Create Firebase Authenticated User';
            userData = await createFirebaseUser(data);
            userId = userData.user.uid;
         } catch (error) {
            console.error('Create Firebase Authenticated User' + " error:", error);
            dispatch(createNewUserFail(userId, step, error));
            throw error
         }
      }
      // Check for affiliation data
      try {
         step = "Get third party affiliation data (skovdyrkerne)"
         if (affData && affData.filter(aff => aff.name === 'skovdyrkerne').length > 0) {
            affDataUp = await getAffiliationData(data.email);
         }
      } catch (error) {
         console.error(error)
         dispatch(createNewUserFail(userId, step, error))
      }
      // Get token from getStream based new user
      try {
         step = 'Fetch getStream Token';
         token = await fetchTokenGetStream(userId);
      } catch (error) {
         console.error(step + " error:", error);
         dispatch(createNewUserFail(userId, step, error))
      }

      // Save user data on firestore
      try {
         step = "Save user data on Firestore"
         await saveUserData(data, userId, token, signUpObject, affDataUp);
      } catch (error) {
         console.error(step + " error:", error);
         dispatch(createNewUserFail(userId, step, error))
      }

      // attach getStream
      try {
         step = 'Attach getStream to user';
         await dispatch(attachGetStream(userId, token, data));
      } catch (error) {
         console.error(step + " error:", error);
         dispatch(createNewUserFail(userId, step, error))
      }

      // Send new user signup email to us
      try {
         step = 'Send new user signup email to us';
         await dispatch(sendWelcomeEmail(data.email, data.name, userId, signUpObject.affiliation ? signUpObject.affiliation : 'not set'));
      } catch (error) {
         console.error(step + " error:", error);
         dispatch(createNewUserFail(userId, step, error))
      }

      // If everything succeds
      step = 'Create user finish';
      dispatch(createNewUserSuccess())
   } catch (error) {
      // TODO: create new user fail needs to be implemented
      dispatch(createNewUserFail("NA", "not captured!", error))
      // Delete any content created on firebase, reepay, mailchimp, ..., throw message to user and reload app to signin
   }
}

const createFirebaseUser = async (data) => {
   const userData = await firebase.auth().createUserWithEmailAndPassword(data.email, data.passwordNew);
   return userData;
}

// Link user and org
const linkUserAndOrgStart = () => {
   return {
      type: actionTypes.LINK_USER_AND_ORG_START,
   };
}

const linkUserAndOrgFail = (error) => {
   return {
      type: actionTypes.LINK_USER_AND_ORG_FAIL,
      error: error
   };
}

const linkUserAndOrgSuccess = () => {
   return {
      type: actionTypes.LINK_USER_AND_ORG_SUCCESS,
   };
}

export const linkUserAndOrg = (data) => async (dispatch) => {
   // Set up database links
   const db = firebase.firestore();
   const user = firebase.auth().currentUser;
   dispatch(linkUserAndOrgStart());
   // Unpack data object
   const cvr = data.cvr;
   // Chekc if org already exist
   try {
      const orgs = db.collection('org');
      const orgRef = await orgs.where('cvr', '==', cvr).get();
      if (orgRef.empty) {
         // The org does not exist, so create it
         const newOrgId = createNewOrg(user.uid, data)
         // Link the org id to the user
         const refId = db.collection("users").doc(user.uid);
         refId.update({
            org: newOrgId
         });
      } else {
         // The org exist so get id and update user and affiliate user to the org
         orgRef.forEach(doc => {
            // Link the org id to the user
            const refId = db.collection("users").doc(user.uid);
            refId.update({
               org: doc.id,
               orgAccess: 'denied'
            });
            // Affiliate user to org
            const org = db.collection("org").doc(doc.id);
            org.update({
               users: firebase.firestore.FieldValue.arrayUnion(user.uid)
            });
            // Send email to us that user signed up for existing org
            dispatch(sendEmail("orgNewUser", user.uid, "", { userId: user.uid, orgId: doc.id }))
         })
      }
      dispatch(linkUserAndOrgSuccess());
   } catch (error) {
      console.error("Failed to link user and org!!!", error)
      sendUserSignUpErrorEmail(user.uid, 'Link user and org', error);
      dispatch(linkUserAndOrgFail(error));
   }
}

const createNewOrg = (userId, data) => {
   let db = firebase.firestore();
   const orgIdFetched = db.collection("org").doc();
   orgIdFetched.set({
      name: typeof data.name !== "undefined" ? data.name : '',
      address: typeof data.address !== "undefined" ? data.address : '',
      city: typeof data.city !== "undefined" ? data.city : '',
      zip: typeof data.zipcode !== "undefined" ? data.zipcode : '',
      email: data.email ? data.email : '',
      phoneNumber: data.phoneNumber ? data.phoneNumber : '',
      forests: [],
      users: [userId],
      id: orgIdFetched.id,
      cvr: data.cvr ? data.cvr : '',
      cvrData: typeof data.cvrData !== "undefined" ? data.cvrData : '',
      affiliation: {
         ds: {
            status: data.dsStatus ? "member" : "not member",
         }
      }
   });
   return orgIdFetched.id;
}

// const affiliateUserToOrg = (orgId, userId) => async (dispatch) => {
//     let db = firebase.firestore();
//     // Append userId to orgs user list
//     const org = db.collection("org").doc(orgId);
//     org.update({
//         users: firebase.firestore.FieldValue.arrayUnion(userId)
//     });
// }

const saveUserData = async (data, userId, token, signUpObject, affData) => {
   let db = firebase.firestore();
   try {
      // Set forestId list dependent on affiliation
      let forestIdList = [];
      let selectedForestIdList = [];
      // Check for affiliation data
      let affUpData = {
         ds: {
            status: signUpObject.affiliation === "danskskovforening" ? "member" : "not member",
         }
      };
      if (affData && affData.length > 0) {
         affData.forEach(el => {
            // Check for HD affiliation
            if (el.aff && el.aff.name && el.aff.name === 'HD') {
               if (el.userData.length > 0) {
                  affUpData = {
                     ...affUpData,
                     [el.aff.name]: {
                        blok_id_list: el.userData[0].blok_id_list ? el.userData[0].blok_id_list : [],
                        blok_id_list_portal: el.userData[0].blok_id_list_portal ? el.userData[0].blok_id_list_portal : el.userData[0].blok_id_list ? el.userData[0].blok_id_list : [],
                        isForester: el.userData[0].isForester ? el.userData[0].isForester : false,
                        emailForester: el.userData[0].isForester ? data.email : '',
                        status: 'active'
                     }
                  }
               } else {
                  // Create the user but send email to us about affiliation issues
                  affUpData = {
                     ...affUpData,
                     [el.aff.name]: {
                        blok_id_list: [],
                        blok_id_list_portal: [],
                        isForester: false,
                        emailForester: '',
                        status: 'active'
                     }
                  }
                  sendUserSignUpErrorEmail(userId, "Save user data, set affiliation", {
                     message: "The affiliation was found, but there where no user data associated to it. We have still created the user.",
                     data: data,
                     affData: affData
                  })
               }
            }
            // Check for skovdyrkerne affiliation
            if (el.aff && el.aff.name && el.aff.name === 'skovdyrkerne') {
               // Check for data under affiliation
               if (el.userData.length > 0) {
                  affUpData = {
                     ...affUpData,
                     [el.aff.name]: {
                        user_id: el.userData[0].skovdyrkerne_id ? el.userData[0].skovdyrkerne_id : '',
                        forestList: el.userData[0].forestId ? [el.userData[0].forestId] : [],
                        isForester: el.userData[0].isForester ? el.userData[0].isForester : false,
                        section: el.userData[0].section ? el.userData[0].section : '',
                        colorScheme: el.userData[0].colorScheme ? el.userData[0].colorScheme : '',
                        status: 'active'
                     }
                  }
               } else {
                  // Create the user but send email to us about affiliation issues
                  affUpData = {
                     ...affUpData,
                     [el.aff.name]: {
                        user_id: '',
                        forestList: [],
                        isForester: false,
                        subscriptions: [],
                        status: 'active'
                     }
                  }
                  sendUserSignUpErrorEmail(userId, "Save user data, set affiliation", {
                     message: "The affiliation was found, but there where no user data associated to it. We have still created the user.",
                     data: data,
                     affData: affData
                  })
               }
            }
            // Check for SLS affiliation
            if (el.name && el.name === 'sls') {
               // affUp
               affUpData = {
                  ...affUpData,
                  [el.name]: {
                     status: el.status
                  }
               }
            }
            // TODO : Check for other affiliations
         })
      }
      // --- Check for HD blok id list and map to forest ids --- //
      if (affUpData.HD && affUpData.HD.blok_id_list && affUpData.HD.blok_id_list.length > 0) {
         // map blok_id_list to skovkortet.dk forestIds
         const blokForestIdsList = await mapBlokIdListToForestIds(affUpData.HD.blok_id_list);
         forestIdList = blokForestIdsList.map(el => el.id);
      }
      if (affUpData.HD && affUpData.HD.blok_id_list_portal.length > 0) {
         // map blok_id_list_portal to skovkortet.dk selectedForestIds
         const blokPortalForestIdsList = await mapBlokIdListToForestIds(affUpData.HD.blok_id_list_portal);
         selectedForestIdList = blokPortalForestIdsList.map(el => el.id);
      }
      // --- Check for skovdyrkerne forest id list
      if (affUpData.skovdyrkerne && affUpData.skovdyrkerne.forestList.length > 0) {
         const sdForestList = await mapSkovdyrkerneForestIdToForestId(affUpData.skovdyrkerne.forestList);
         forestIdList = sdForestList.map(el => el.id);
         selectedForestIdList = forestIdList;
         // update affiliation forest list
         affUpData.skovdyrkerne.forestList = forestIdList;
         // Update skovdyrkerne forest with color scheme par
         if (forestIdList.length > 0) {
            try {
               await db.collection("forests").doc(forestIdList[0]).update({
                  skovdyrkerne: {
                     section: affUpData.skovdyrkerne.section,
                     colorScheme: affUpData.skovdyrkerne.colorScheme
                  }
               })
            } catch (error) {
               console.error(error);
            }
         }
      }
      // Check if selected forest id list contains forest id's not in the forest id list
      const diff = selectedForestIdList.filter(x => !forestIdList.includes(x));
      forestIdList = [...forestIdList, ...diff];

      // Save the data on cloud
      if (data.userRole === 'forester') {
         let userRole = 'forester'
         const refId = db.collection("users").doc(userId);
         await refId.set({
            name: (data && data.name) ? data.name : '',
            address: (data && data.address) ? data.address : '',
            city: (data && data.city) ? data.city : '',
            zip: (data && data.zip) ? data.zip : '',
            email: data.email,
            forests: forestIdList ? forestIdList : [],
            selectedForests: selectedForestIdList ? selectedForestIdList : [],
            acceptedTerms: (data && data.acceptedTerms) ? data.acceptedTerms : false,
            onboarded: false,
            newUser: true,
            subscription: 'free',
            userRole: userRole,
            id: userId,
            packages: (data && data.packages) ? data.packages : [],
            creationDate: moment().format(),
            getStreamToken: token,
            signUpObject: signUpObject ? signUpObject : {},
            affiliation: affUpData ? affUpData : {}
         });
      } else if (data.userRole === "forestOwner") {
         let userRole = "forestOwner";
         // Create user
         const refId = db.collection("users").doc(userId);
         await refId.set({
            name: (data && data.name) ? data.name : '',
            address: (data && data.address) ? data.address : '',
            city: (data && data.city) ? data.city : '',
            zip: (data && data.zip) ? data.zip : '',
            email: data.email,
            forests: forestIdList ? forestIdList : [],
            selectedForests: selectedForestIdList ? selectedForestIdList : [],
            acceptedTerms: (data && data.acceptedTerms) ? data.acceptedTerms : false,
            onboarded: false,
            newUser: true,
            subscription: 'free',
            userRole: userRole,
            id: userId,
            creationDate: moment().format(),
            packages: (data && data.packages) ? data.packages : [],
            getStreamToken: token,
            signUpObject: signUpObject ? signUpObject : {},
            affiliation: affUpData ? affUpData : {}
         });
      }
   } catch (err) {
      console.error("Save user data error: ", err)
      throw err;
   }
}

const updateUserDataSignUp = (data, affData) => async (dispatch) => {
   try {
      // Set forestId list dependent on affiliation
      let forestIdList = [];
      let selectedForestIdList = [];
      // Check for affiliation data
      let affUpData = { ...data.affiliation };
      if (affData && affData.length > 0) {
         affData.forEach(el => {
            // Check for HD affiliation
            if (el.aff.name === 'HD') {
               if (el.userData.length > 0) {
                  affUpData = {
                     ...affUpData,
                     [el.aff.name]: {
                        blok_id_list: el.userData[0].blok_id_list ? el.userData[0].blok_id_list : [],
                        blok_id_list_portal: el.userData[0].blok_id_list_portal ? el.userData[0].blok_id_list_portal : el.userData[0].blok_id_list ? el.userData[0].blok_id_list : [],
                        isForester: el.userData[0].isForester ? el.userData[0].isForester : false,
                        emailForester: el.userData[0].isForester ? data.email : '',
                        status: 'active'
                     }
                  }
               } else {
                  // Create the user but send email to us about affiliation issues
                  affUpData = {
                     ...affUpData,
                     [el.aff.name]: {
                        blok_id_list: [],
                        blok_id_list_portal: [],
                        isForester: false,
                        emailForester: '',
                        status: 'active'
                     }
                  }
                  sendUserSignUpErrorEmail(data.id, "Save user data, set affiliation", {
                     message: "The affiliation was found, but there where no user data associated to it. We have still created the user.",
                     data: data,
                     affData: affData
                  })
               }
            }
            // TODO : Check for other affiliations
         })
      }
      // Check for HD blok id list and map to forest ids
      if (affUpData.HD && affUpData.HD.blok_id_list && affUpData.HD.blok_id_list.length > 0) {
         // map blok_id_list to skovkortet.dk forestIds
         const blokForestIdsList = await mapBlokIdListToForestIds(affUpData.HD.blok_id_list);
         forestIdList = blokForestIdsList.map(el => el.id);
      }
      if (affUpData.HD && affUpData.HD.blok_id_list_portal.length > 0) {
         // map blok_id_list_portal to skovkortet.dk selectedForestIds
         const blokPortalForestIdsList = await mapBlokIdListToForestIds(affUpData.HD.blok_id_list_portal);
         selectedForestIdList = blokPortalForestIdsList.map(el => el.id);
      }
      // Check if selected forest id list contains forest id's not in the forest id list
      const diff = selectedForestIdList.filter(x => !forestIdList.includes(x));
      forestIdList = [...forestIdList, ...diff];

      // Save the data on cloud
      const updatedData = {
         forests: forestIdList,
         selectedForests: selectedForestIdList,
         affiliation: affUpData ? affUpData : {}
      }
      dispatch(updateUserData(updatedData));
   } catch (err) {
      console.error("Save user data error: ", err)
   }

}

const fetchTokenGetStream = async (firebaseUserId) => {
   let token = null;
   try {
      const response = await fetch(configGetStream.getTokenURL, {
         method: 'POST',
         credentials: "include",
         headers: {
            "Content-Type": "Application/JSON",
            "Access-Control-Request-Method": "POST"
         },
         body: JSON.stringify({
            userId: firebaseUserId,
         })
      })
      const data = await response.json();
      token = data.token;
   } catch (err) {
      console.error("Fetch getStream.io error: ", err)
      throw err;
   }
   return token;
}

// Mail chimp
// Add user to mail chimp audience list
// const attachMailChimpStart = () => {
//    return {
//       type: actionTypes.ATTACH_MAIL_CHIMP_START,
//    };
// }

// const attachMailChimpFail = (error) => {
//    return {
//       type: actionTypes.ATTACH_MAIL_CHIMP_FAIL,
//       error: error
//    };
// }

// const attachMailChimpSuccess = () => {
//    return {
//       type: actionTypes.ATTACH_MAIL_CHIMP_SUCCESS,
//    };
// }

// export const attachMailChimp = (listId, userData, tags) => async dispatch => {
//    dispatch(attachMailChimpStart());
//    // Divide name into first and last
//    const nameSplit = userData.name.split(' ');
//    const firstName = nameSplit[0];
//    const lastName = nameSplit.slice(1).join(' ');

//    // Set body data and check for tag
//    let bodyData = {
//       listId: listId,
//       email: userData.email,
//       firstName: firstName,
//       lastName: lastName
//    }
//    if (tags && tags.length > 0) {
//       bodyData = { ...bodyData, tags: tags };
//    }
//    const requestOptions = {
//       method: 'POST',
//       credentials: "include",
//       headers: {
//          "Content-Type": "Application/JSON",
//          "Access-Control-Request-Method": "POST"
//       },
//       body: JSON.stringify(bodyData)
//    }
//    try {
//       const response = await fetch(configMailChimp.addUserToAudienceURL, requestOptions);
//       const returnData = await response.json();
//       dispatch(attachMailChimpSuccess(returnData));
//    } catch (error) {
//       console.error("Add user to mail chimp audience list error: ", error)
//       sendUserSignUpErrorEmail(userData.email, 'Attach user to mailChimp audience. List ID: ' + listId, error);
//       dispatch(attachMailChimpFail(error));
//    }
// }
// Get user tags from mailChimp
// export const getUserTagsFromMailChimpAudience = (listId, userData) => async (dispatch) => {
//    const requestOptions = {
//       method: 'POST',
//       credentials: "include",
//       headers: {
//          "Content-Type": "Application/JSON",
//          "Access-Control-Request-Method": "POST"
//       },
//       body: JSON.stringify({
//          listId: listId,
//          email: userData.email
//       })
//    }
//    try {
//       const response = await fetch(configMailChimp.getUserTagFromAudienceURL, requestOptions);
//       const responseData = await response.json();
//       if (response.status !== 200) {
//          return "user not found";
//       } else {
//          if (responseData.response.total_items === 0) {
//             return "no tags available";
//          } else {
//             const tags = responseData.response.tags.filter(el => {
//                return el.name === "DS";
//             });
//             if (tags.length > 0) {
//                return responseData.response;
//             } else {
//                return "no ds tag";
//             }
//          }
//       }
//    } catch (error) {
//       console.error("Get user tags from mailChimp, error: ", error)
//       throw error;
//    }
// }
// Remove user from mail chimp audience list
// const removeUserfromMailChimpAudienceListStart = () => {
//    return {
//       type: actionTypes.REMOVE_USER_FROM_MAIL_CHIMP_AUDIENCE_LIST_START,
//    };
// }

// const removeUserfromMailChimpAudienceListSuccess = () => {
//    return {
//       type: actionTypes.REMOVE_USER_FROM_MAIL_CHIMP_AUDIENCE_LIST_SUCCESS,
//    };
// }

// const removeUserfromMailChimpAudienceListFail = (error) => {
//    return {
//       type: actionTypes.REMOVE_USER_FROM_MAIL_CHIMP_AUDIENCE_LIST_FAIL,
//       error: error,
//    };
// }

// export const removeUserfromMailChimpAudienceList = (listId, mailChimpUserId) => async (dispatch) => {
//    dispatch(removeUserfromMailChimpAudienceListStart());
//    const requestOptions = {
//       method: 'POST',
//       credentials: "include",
//       headers: {
//          "Content-Type": "Application/JSON",
//          "Access-Control-Request-Method": "POST"
//       },
//       body: JSON.stringify({
//          listId: listId,
//          mailChimpId: mailChimpUserId
//       })
//    }
//    try {
//       const response = await fetch(configMailChimp.removeUserFromAudienceURL, requestOptions);
//       const responseData = await response.json();
//       if (responseData.status !== 200) {
//          dispatch(removeUserfromMailChimpAudienceListFail(responseData));
//       }
//       dispatch(removeUserfromMailChimpAudienceListSuccess(responseData));
//    } catch (err) {
//       console.error("Attach mail chimp to user, error: ", err)
//       dispatch(removeUserfromMailChimpAudienceListFail(err));
//    }
// }

// Add tag to user from mail chimp audience list
// const addTagToUserfromMailChimpAudienceListStart = () => {
//    return {
//       type: actionTypes.ADD_TAG_TO_USER_FROM_MAIL_CHIMP_AUDIENCE_LIST_START,
//    };
// }

// const addTagToUserfromMailChimpAudienceListSuccess = () => {
//    return {
//       type: actionTypes.ADD_TAG_TO_USER_FROM_MAIL_CHIMP_AUDIENCE_LIST_SUCCESS,
//    };
// }

// const addTagToUserfromMailChimpAudienceListFail = (error) => {
//    return {
//       type: actionTypes.ADD_TAG_TO_USER_FROM_MAIL_CHIMP_AUDIENCE_LIST_FAIL,
//       error: error,
//    };
// }

// export const addTagToUserfromMailChimpAudienceList = (listId, email, tag) => async (dispatch) => {
//    dispatch(addTagToUserfromMailChimpAudienceListStart());
//    const requestOptions = {
//       method: 'POST',
//       credentials: "include",
//       headers: {
//          "Content-Type": "Application/JSON",
//          "Access-Control-Request-Method": "POST"
//       },
//       body: JSON.stringify({
//          listId: listId,
//          email: email,
//          tag: tag
//       })
//    }
//    try {
//       const response = await fetch(configMailChimp.addTagToUserFromAudienceURL, requestOptions);
//       const responseData = await response.json();
//       if (responseData.status !== 200) {
//          dispatch(addTagToUserfromMailChimpAudienceListFail(responseData));
//       }
//       dispatch(addTagToUserfromMailChimpAudienceListSuccess(responseData));
//    } catch (err) {
//       console.error("Add tag to mailchimp user error: ", err)
//       dispatch(addTagToUserfromMailChimpAudienceListFail(err));
//    }
// }

// GetStream.io
const attachGetStream = (firebaseUserId, token, userData) => async (dispatch) => {
   try {
      // Call create user
      await dispatch(createUserGetStream(firebaseUserId, token, userData))
   } catch (err) {
      console.error("getStream create user error: ", err)
      throw err;
   }

   try {
      // Call follow feed
      await dispatch(setFollowFeedGetStream(token, firebaseUserId, "timeline", configGetStream.adminId, "user"));
   } catch (err) {
      console.error("getStream set follow feed error: ", err)
      throw err;
   }
}

const createUserGetStream = (firebaseUserId, token, userData) => async (dispatch) => {
   const streamClient = stream.connect(configGetStream.apiKey, token, configGetStream.appId);

   streamClient.user(firebaseUserId).getOrCreate({
      name: userData.name,
      fbId: firebaseUserId,
      userRole: userData.userRole,
   }).then().catch(err => {
      console.error("Create user for getStream.io error: ", err)
      throw err;
   });
}

const setFollowFeedGetStream = (token, firebaseUserId, userFeed, followId, followFeed) => async (dispatch) => {
   const streamClient = stream.connect(configGetStream.apiKey, token, configGetStream.appId);
   const feed = streamClient.feed(userFeed, firebaseUserId)
   feed.follow(followFeed, followId).then(data => {
   }).catch(err => {
      console.error("Set get stream follow feed error: ", err)
      throw err;
   });
}

// Emails
const sendWelcomeEmail = (emailAddress, name, userId, affiliation) => async (dispatch) => {
   let db = firebase.firestore();
   // send the email based on template on firebase
   db.collection('mail').add({
      to: 'app@skovkortet.dk',
      message: {
         subject: "New user signup",
         text: "User name: " + name + "\nId: " + userId + '\nEmail: ' + emailAddress + '\nAffiliation: ' + affiliation
      }
   })
}

const sendUserSignUpErrorEmail = async (userId, step, error) => {
   let db = firebase.firestore();
   // send the email based on template on firebase
   let errorObj = JSON.stringify(error);
   db.collection('mail').add({
      to: 'app@skovkortet.dk',
      message: {
         subject: 'Sign Up Error, User Id: ' + userId,
         text: "Error happended at step: " + step + '\n\n Error object: \n\n' + errorObj,
      }
   })
}

// Todo email
export const sendTodoEmailStart = () => {
   return {
      type: actionTypes.SEND_TODO_EMAIL_START,
   };
}

export const sendTodoEmailSuccess = () => {
   return {
      type: actionTypes.SEND_TODO_EMAIL_SUCCESS,
   };
}

export const sendTodoEmailFail = (error) => {
   return {
      type: actionTypes.SEND_TODO_EMAIL_FAIL,
      error: error,
   };
}

export const sendTodoEmail = (todoData) => async (dispatch) => {
   let db = firebase.firestore();
   dispatch(sendTodoEmailStart());
   // Form the email based on firestore template
   let template = "todoEmail";

   // Form the text input
   const subject = todoData.title;
   const forest = todoData.forestData.forestName;
   const date = moment().format('DD[-]MM[-]YYYY HH:mm');
   const from = todoData.sender;
   const assignment = todoData.text;
   const state = todoData.todoStatus;
   const link = 'https://www.google.com/maps/search/?api=1&query=' + todoData.latlng.lat + ',' + todoData.latlng.lng;

   // send the email based on template on firebase
   try {
      db.collection('mail').add({
         to: todoData.team.email,
         replyTo: '(no reply)',
         template: {
            name: template,
            data: {
               title: subject,
               date: date,
               from: from,
               forest: forest,
               text: assignment,
               state: state,
               link: link
            }
         }
      }).then(docRef => {
         docRef.onSnapshot(doc => {
            // Check if email is sending
            if (doc.data().delivery) {
               if (doc.data().delivery.state === "SUCCESS") {
                  if (todoData.withSnackbar) {
                     dispatch(sendTodoEmailSuccess());
                  }
               } else if (doc.data().delivery.state === "ERROR") {
                  if (todoData.withSnackbar) {
                     dispatch(sendTodoEmailFail(doc.data().delivery.error));
                  }
               }
            }
         })
      }).catch(error => {
         if (todoData.withSnackbar) {
            dispatch(sendTodoEmailFail(error));
         }
      });
   } catch (error) {
      if (todoData.withSnackbar) {
         dispatch(sendTodoEmailFail(error));
      }
   }
}

// Integration email
export const sendIntegrationEmailStart = () => {
   return {
      type: actionTypes.SEND_INTEGRATION_EMAIL_START,
   };
}

export const sendIntegrationEmailSuccess = () => {
   return {
      type: actionTypes.SEND_INTEGRATION_EMAIL_SUCCESS,
   };
}

export const sendIntegrationEmailFail = (error) => {
   return {
      type: actionTypes.SEND_INTEGRATION_EMAIL_FAIL,
      error: error,
   };
}

export const sendIntegrationEmail = (type, email, data) => async (dispatch) => {
   let db = firebase.firestore();
   dispatch(sendIntegrationEmailStart());
   // Form the email based on firestore template
   let template = "kwIntegration";
   if (type === "kw") {
      template = "kwIntegration"
   }

   // send the email based on template on firebase
   try {
      db.collection('mail').add({
         to: email,
         replyTo: '(no reply)',
         template: {
            name: template,
            data: {
               forestId: data.id,
               ownerName: data.name,
               forestName: data.forestName
            }
         }
      }).then(docRef => {
         docRef.onSnapshot(doc => {
            // Check if email is sending
            if (doc.data().delivery) {
               if (doc.data().delivery.state === "SUCCESS") {
                  dispatch(sendIntegrationEmailSuccess());
               } else if (doc.data().delivery.state === "ERROR") {
                  dispatch(sendIntegrationEmailFail(doc.data().delivery.error));
               }
            }
         })
      }).catch(error => {
         dispatch(sendIntegrationEmailFail(error));
      });
   } catch (error) {
      dispatch(sendIntegrationEmailFail(error));
   }
}

export const sendEmail = (type, userId, customerEmail, content) => async () => {
   let db = firebase.firestore();
   // send the email
   let subject = 'Unknown email type';
   let text = 'Unknown email type'
   switch (type) {
      case "cancelSubscription": subject = "User cancled subscription"; text = "User id: " + userId + ', has cancled the subscription type: ' + content.type + '.\n\nReason: \n' + content.title; break;
      case "inviteCustomer": subject = "Forester invited customer"; text = "Forester with user name: " + content.userData.name + ", id: " + userId + ", email: " + content.userData.email + ", invited customer with email: " + customerEmail; break;
      case "newFeatureRequest": subject = "New feature request"; text = "User: " + content.userData.name + ", id: " + userId + ", email: " + content.userData.email + ", has made a feature request. \n\nTitel:\n" + content.title + "\n\n Indhold:\n" + content.text; break;
      case "uncancelSubscription": subject = "User uncancled subscription"; text = "User id: " + userId + ', has uncancled the subscription type: ' + content.type + '.\n\nReason: \n' + content.title; break;
      case "commentOnFeed": subject = "A user has commented on a post in the feed"; text = "User: " + content.userData.name + ", id: " + userId + ", email: " + content.userData.email + ', has commented on the following post:\n\n id: ' + content.post.id + '\nContent: ' + content.post.text + '.\n\nComment: ' + content.comment; break;
      case "hdNoEmailforesterSendNote": subject = "[HD Send Note] There is no forester email connected to the forest"; text = "Forest id: " + content.forestId + "\nUser that tried to send email: " + content.userData.name + ", id: " + userId; break;
      case "orgNewUser": subject = "[New user to Org] A new user is being added to existing org"; text = "User id: " + content.userId + "\nOrg id: " + content.orgId; break;
      case "signupReason": subject = "[Signup Reason] Reason that the user has signed up"; text = "User id: " + userId + "\n\nReason:\n" + content.reason; break;
      default: subject = "Unknown email type"; text = "...";
   }
   try {
      db.collection('mail').add({
         to: 'app@skovkortet.dk',
         message: {
            subject: subject,
            text: text,
         }
      })
   } catch (error) {
      console.error("Error in sending email!", error)
   }
}

// Forest/User event log - saved to forest
export const addEventToEventLog = (forestId, data) => async (dispatch) => {
   let db = firebase.firestore();
   try {
      const refId = db.collection("forests").doc(forestId).collection("eventLog").doc();
      const id = refId.id;
      await refId.set({
         id: id,
         forestId: forestId,
         timestamp: moment().format(),
         ...data
      });
   } catch (err) {
      console.error("Add event to event log error: ", err)
   }
}